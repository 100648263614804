import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Avatar } from "@mui/material"

const  NotificationMenu = ({ openNotificationMenu, onNotificationClose }) => {
    const [anchorElNotification, setAnchorElNotification] = useState(false);
    const openNotification = Boolean(anchorElNotification);

    const testData = [
        {
            avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
            message: " has shared a new video"

        },
        {
            avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
            message: " has shared a new video"

        },
        {
            avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
            message: " has shared a new video"

        }
    ]

    useEffect(() => {
        setAnchorElNotification(openNotificationMenu);
    })

    const handleNotificationClose = () => {
        onNotificationClose(null)
    };


    return (
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorElNotification}
                open={openNotification}
                onClose={handleNotificationClose}
                TransitionComponent={Fade}
            >
                {testData.map(item => {
                    return (
                        <MenuItem>

                            <ListItemIcon>
                                <Avatar
                                    alt="Remy Sharp 3"
                                    src={item.avatar}
                                    sx={{ width: 30, height: 30 }}
                                    style={{
                                        borderRadius: '50%',
                                    }}

                                />
                            </ListItemIcon>
                            <Typography variant="inherit" variant="h6" noWrap>
                                TerryCuloys &nbsp;
                            </Typography>
                            
                            <Typography variant="inherit" variant="subtitle1" noWrap>
                                {item.message}
                            </Typography>
                        </MenuItem>
                    )
                })}
            </Menu>
    );
}

export default NotificationMenu;