import "./App.css";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import HomePage from "./views/HomePage";
import Layouts from "./Components/Layouts";
import ProfilePage from "./views/Profile";
import PostPage from "./views/Post";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { useState } from "react";
import DiscoverPage from "./views/Discover";
import AccountPage from "./views/Account";
import AccountLanguagePage from "./views/Account/Language";
import AccountSettingPage from "./views/Account/Setting";
import AccountInfoPage from "./views/Account/MyAccount";
import AccountUsernamePage from "./views/Account/MyAccount/Username";
import AccountEmailPage from "./views/Account/MyAccount/Email";
import AccountPasswordPage from "./views/Account/MyAccount/Password";
import AccountPhonePage from "./views/Account/MyAccount/Phone";
import AccountSettingProfilePage from "./views/Account/Setting/EditProfile";
import EventBus from "./core/EventBus";
import DiscoverPosts from "./views/DiscoverPosts";
import DiscoverPostsAll from "./views/DiscoverPosts/DiscoverPostsAll";
import LoginPage from "./views/Auth/Login";
import RegisterPage from "./views/Auth/Register";
import ProtectedRoute from "./routes/ProtectedRoute/ProtectedRoute";
import NotificationPage from "./views/NotificationPage";
import MessagePage from "./views/MessagePage";
import ReferPage from "./views/ReferPage";

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1700,
    },
  },
  typography: {
    fontFamily: "Poppins, Arial",
    button: {
      textTransform: "none",
    },
    h3: {
      fontSize: "18px",
      color: "primary",
      fontWeight: "700",
    },
    h4: {
      fontSize: "16px",
      color: "primary",
      fontWeight: "700",
    },
    h5: {
      fontWeight: "700",
      color: "primary",
      fontSize: "15px",
    },
    h6: {
      fontWeight: "700",
      color: "primary",
      fontSize: "13px",
    },
    subtitle1: {
      fontSize: "13px",
      color: "primary",
      fontWeight: "400",
      lineHeight: "16px",
    },
  },
});

const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: "dark",
    pink: {
      main: "#FF8291",
    },
    primary: {
      main: "#FFFFFF",
      iconColor: "#FF8291",
    },
    secondary: {
      main: "#FF8291",
      iconColor: "#FF8291",
    },
    dark: {
      main: "#121826",
    },
    light: {
      main: "#FFFFFF",
    },
    referPage: {
      overlay: " rgba(0, 0, 0, 0.7)",
      referalCode: "rgba(249, 250, 251, 0.1)",
      displayLightIcons: "hidden",
      displayDarkIcons: "visible",
      bodyBgColor: "rgba(255, 255, 255, 0.05)",
      textColor: "#FF8291",
      iconColor: "#FF8291",
    },
  },
});
const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    pink: {
      main: "#FF8291",
    },
    primary: {
      main: "#121826",
      iconColor: "#000000",
    },
    secondary: {
      main: "#121826",
      iconColor: "#262626",
    },
    dark: {
      main: "#ffffff",
    },
    light: {
      main: "#FFFFFF",
    },
    referPage: {
      overlay: "rgba(255, 255, 255, 0.6)",
      referalCode: "rgba(18, 24, 38, 0.1)",
      displayLightIcons: "visible",
      displayDarkIcons: "hidden",
      bodyBgColor: "rbga(0,0,0,0.3)",
      textColor: "rgba(18, 24, 38, 0.8)",
      iconColor: "rgba(0, 0, 0, 0.87)",
    },
  },
});

function App() {
  const [navType, setNavType] = useState("navbar");
  const [profileData, setProfileData] = useState({});
  const [header, setHeader] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("login"));

  useEffect(() => {
    EventBus.on("setTheme", (theme) => {
      if (theme) {
        if (theme === "dark") {
          setDarkMode(true);
        } else {
          setDarkMode(false);
        }
      }
    });
    if (localStorage.getItem("mode") === "dark") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  const handleRefresh = (e) => {
    window.location.reload();
  };

  return (
    <Router>
      {/* material ui theme */}
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <Layouts navType={navType} profileData={profileData} header={header}>
          <PullToRefresh onRefresh={handleRefresh}>
            <Routes>
              <Route exact path="/" element={<ProtectedRoute />}>
                <Route path="/" element={<HomePage navType={setNavType} />} />

                <Route
                  path="/profile/:username"
                  element={
                    <ProfilePage
                      navType={setNavType}
                      profileData={setProfileData}
                    />
                  }
                />
                <Route
                  path="/discover"
                  element={<DiscoverPage navType={setNavType} />}
                />

                <Route
                  path="/discover/all/:post"
                  element={<DiscoverPostsAll navType={setNavType} />}
                />

                <Route
                  path="/discover/user/:username/:post"
                  element={<DiscoverPosts navType={setNavType} />}
                />
                <Route
                  path="/inbox"
                  element={
                    <MessagePage
                      navType={setNavType}
                      profileData={setProfileData}
                    />
                  }
                />

                <Route
                  path="/referandearn"
                  element={
                    <ReferPage navType={setNavType} header={setHeader} />
                  }
                />

                <Route
                  path="/account"
                  element={
                    <AccountPage
                      navType={setNavType}
                      isLoggedIn={setIsLoggedIn}
                    />
                  }
                />

                <Route
                  path="/account/language"
                  element={
                    <AccountLanguagePage
                      navType={setNavType}
                      header={setHeader}
                    />
                  }
                />

                <Route
                  path="/account/setting"
                  element={
                    <AccountSettingPage
                      navType={setNavType}
                      header={setHeader}
                    />
                  }
                />

                <Route
                  path="/account/setting/profile/edit"
                  element={
                    <AccountSettingProfilePage
                      navType={setNavType}
                      header={setHeader}
                    />
                  }
                />

                <Route
                  path="/profile/:username/:post"
                  element={
                    <PostPage
                      navType={setNavType}
                      profileData={setProfileData}
                    />
                  }
                />

                <Route
                  path="/account/info"
                  element={
                    <AccountInfoPage navType={setNavType} header={setHeader} />
                  }
                />
                <Route
                  path="/account/username/edit"
                  element={
                    <AccountUsernamePage
                      navType={setNavType}
                      header={setHeader}
                    />
                  }
                />
                <Route
                  path="/account/email/edit"
                  element={
                    <AccountEmailPage navType={setNavType} header={setHeader} />
                  }
                />
                <Route
                  path="/account/password/edit"
                  element={
                    <AccountPasswordPage
                      navType={setNavType}
                      header={setHeader}
                    />
                  }
                />
                <Route
                  path="/account/phone/edit"
                  element={
                    <AccountPhonePage navType={setNavType} header={setHeader} />
                  }
                />
              </Route>

              <Route
                path="/login"
                element={<LoginPage isLoggedIn={setIsLoggedIn} />}
              />
              <Route
                path="/register"
                element={<RegisterPage navType={setNavType} />}
              />
              <Route
                path="/notification"
                element={
                  <NotificationPage navType={setNavType} header={setHeader} />
                }
              />
            </Routes>
          </PullToRefresh>
        </Layouts>
      </ThemeProvider>
    </Router>
  );
}

export default App;
