import React, { useState } from "react";
import {
  Box,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Link
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import useMatchMedia from '../../../utils/useMatchMedia'
import LoginBanner from '../../../assets/img/loginBanner.png'
import PinkLogo from '../../../assets/img/pinkOnlyLogo.png'
import DesktopRegisterPage from "./Desktop";

const useStyles = makeStyles((theme) => ({

  text: {
    color: '#888888',
    fontSize: '16px',
    fontFamily: 'Karla'
  },

  label: {
    marginBottom: "0px !important",
    color: theme.palette.primary.main,
    opacity: '0.8',
    fontSize: '14px',
    fontFamily: 'Karla',
    letterSpacing: '0.05em',
    textTransform: 'uppercase'
  },

  bottomText: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px !important',
    fontFamily: 'Karla',
    fontWeight: "700",
    opacity: '0.8',
    color: theme.palette.secondary.main,
  },

  loginBtn: {
    height: "60px",
    textAlign: "center",
    backgroundColor: "pink",
    marginBottom: "40px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Karla',
    fontWeight: "700",
    opacity: '0.8'
  },

  logoImg: {
    position: "absolute", 
    top: "32%", 
    right: "39%"
  },

  title: {
    marginBottom: "5px !important" ,
    fontSize: "24px !important", 
    color: theme.palette.primary.main,
    opacity: '0.8'
  },

  registerContainer : {
    background: theme.palette.dark.main
  },

  registerFormHeader : {
    padding: "25px 30px",
  },

  registerForm : {
    marginTop: "37px", 
    marginBottom: "90px",
    fontFamily: 'Karla',
  }

}));

const RegisterPage = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const isMobileResolution = useMatchMedia("(min-width:640px)", true);

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      {
        // Smaller screen check
        isMobileResolution ? (  
          <DesktopRegisterPage/>
        ) : 

    <Grid container className = {classes.registerContainer}>

       <Grid item xs={12} style = {{position: "relative"}}>   
        <Box style = {{position: "relative" }}>
          <CardMedia
            style={{ objectFit: "fill"}}
            component="img"
            height="183"
            image={LoginBanner}
          />
        </Box>
        <Box className = {classes.logoImg}>
        <img src={PinkLogo} alt="logo" height = "49" width = "92"/>
        </Box>
      </Grid>

      <Grid item xs={12} className ={classes.registerFormHeader}>
        <Box>
          <Typography variant="h5"  className = {classes.title}>Register</Typography>
          <Typography variant="p" className={classes.text}>Create your account</Typography>
        </Box>
        <Box className = {classes.registerForm}>
        <Typography variant="p" className={classes.label}>Your Name</Typography>
        <TextField
          fullWidth
          hiddenLabel
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: { fontWeight: 300, fontStyle: "normal", fontSize: "12px", marginBottom: "30px", marginTop: "5px",
            backgroundColor : localStorage.getItem("mode") === "dark" ? "rgba(249, 250, 251, 0.1)" : "rgba(0, 0, 0, 0.1)",        
            height: "50px"
          },
          }}
          defaultValue="Anung Jagatara"
          autoFocus
        />

        <Typography variant="p" className={classes.label}>Your Email</Typography>
        <TextField
          fullWidth
          hiddenLabel
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: { fontWeight: 300, fontStyle: "normal", fontSize: "12px", marginBottom: "30px", marginTop: "5px",
            backgroundColor : localStorage.getItem("mode") === "dark" ? "rgba(249, 250, 251, 0.1)" : "rgba(0, 0, 0, 0.1)",
            height: "50px"
          }
          }}
          defaultValue="anung@jagatara.com"
        />

        <Typography variant="p" className={classes.label}>Password</Typography>
        <TextField
          fullWidth
          type= {showPassword ? "text" : "password"}
          hiddenLabel
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: { fontWeight: 300, fontStyle: "normal", fontSize: "12px",  marginBottom: "20px",marginTop: "5px",
            backgroundColor : localStorage.getItem("mode") === "dark" ? "rgba(249, 250, 251, 0.1)" : "rgba(0, 0, 0, 0.1)",         
            height: "50px"
          },
          endAdornment: (
            <InputAdornment position="start" onClick = {handleShowPassword}>
                {showPassword ?
                <VisibilityOutlinedIcon />
                  :
                <VisibilityOffOutlinedIcon/>
                }
              </InputAdornment>
          ),
          }}
          defaultValue="password"
        />
       </Box>
      </Grid>

      <Grid item xs = {12}>
      <Box>
       <Typography variant="p" className={classes.bottomText}>
       <Link href="/login" underline="none"
       style={{
        color:
          localStorage.getItem("mode") === "dark"
            ? "#F9C7C4"
            : "#121826",
      }}
       >
            I have account
        </Link>
        </Typography>
       </Box>
      <Box className = {classes.loginBtn}>
          Register
       </Box>

      </Grid>
    </Grid>
    }
    </>
  );
};

export default RegisterPage;
