import React, { useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Pages from "../../../Components/Pages";
import { makeStyles } from "@mui/styles";
import { Slide } from "@mui/material";

const useStyles = makeStyles(() => ({

  radio: {
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)", paddingTop: "5px", paddingBottom: "5px"
  },

}));

const AccountLanguagePage = ({ navType, header }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("english");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    navType("accountPages");
    header("Choose Language");
  }, []);

  return (
    <Pages>
      <Slide
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
      >
        <FormControl style={{ width: '100%' }}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="english"
              control={<Radio color="pink" />}
              label="English"
              className={classes.radio}
            />
            <FormControlLabel
              value="italian"
              control={<Radio color="pink" />}
              label="Italian"
              className={classes.radio}
            />
            <FormControlLabel
              value="chinese"
              control={<Radio color="pink" />}
              label="Chinese"
              className={classes.radio}
            />
            <FormControlLabel
              value="spanish"
              control={<Radio color="pink" />}
              label="Spanish"
              className={classes.radio}
            />
            <FormControlLabel
              value="russian"
              control={<Radio color="pink" />}
              label="Russian"
              className={classes.radio}
            />
            <FormControlLabel
              value="german"
              control={<Radio color="pink" />}
              label="German"
              className={classes.radio}
            />
            <FormControlLabel
              value="french"
              control={<Radio color="pink" />}
              label="French"
              className={classes.radio}
            />
            <FormControlLabel
              value="arabic"
              control={<Radio color="pink" />}
              label="Arabic"
              className={classes.radio}
            />
            <FormControlLabel
              value="korean"
              control={<Radio color="pink" />}
              label="Korean"
              className={classes.radio}
            />
            <FormControlLabel
              value="portugese"
              control={<Radio color="pink" />}
              label="Portugese"
              className={classes.radio}
            />
          </RadioGroup>
        </FormControl>
      </Slide>
    </Pages>
  );
};

export default AccountLanguagePage;
