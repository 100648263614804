const data = [

    //  List of users with a list of posts within every user.
    // user 1 
    {
        username: 'tanyai',
        name: 'Tanya C',
        profile_image: 'https://ih.vercel.app/img/tanyai/1/01.jpg',
        bio: 'This is my bio.',
        postCount: 77,
        collections: 102,
        followers: 1520,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: false,
                images: [
                    'https://ih.vercel.app/img/tanyai/1/01.jpg',
                    'https://ih.vercel.app/img/tanyai/1/02.jpg',
                    'https://ih.vercel.app/img/tanyai/1/03.jpg',
                    'https://ih.vercel.app/img/tanyai/1/04.jpg',
                    'https://ih.vercel.app/img/tanyai/1/05.jpg',
                    'https://ih.vercel.app/img/tanyai/1/06.jpg',
                    'https://ih.vercel.app/img/tanyai/1/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },

    // user 2
    {
        username: 'marta',
        name: 'Marta S',
        profile_image: 'https://ih.vercel.app/img/martan/1/01.jpg',
        bio: 'Hi, from my bio.',
        postCount: 162,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: true,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/martan/1/01.jpg',
                    'https://ih.vercel.app/img/martan/1/02.jpg',
                    'https://ih.vercel.app/img/martan/1/03.jpg',
                    'https://ih.vercel.app/img/martan/1/04.jpg',
                    'https://ih.vercel.app/img/martan/1/05.jpg',
                    'https://ih.vercel.app/img/martan/1/06.jpg',
                    'https://ih.vercel.app/img/martan/1/07.jpg',
                    'https://ih.vercel.app/img/martan/1/08.jpg',
                    'https://ih.vercel.app/img/martan/1/09.jpg',
                    'https://ih.vercel.app/img/martan/1/10.jpg',
                    'https://ih.vercel.app/img/martan/1/11.jpg',
                    'https://ih.vercel.app/img/martan/1/12.jpg',
                    
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/martan/2/01.jpg',
                    'https://ih.vercel.app/img/martan/2/02.jpg',
                    'https://ih.vercel.app/img/martan/2/03.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-3',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/martan/3/01.jpg',
                    'https://ih.vercel.app/img/martan/3/02.jpg',
                    'https://ih.vercel.app/img/martan/3/03.jpg',
                    'https://ih.vercel.app/img/martan/3/04.jpg',
                    'https://ih.vercel.app/img/martan/3/05.jpg',
                    'https://ih.vercel.app/img/martan/3/06.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            }
        ]
    },

    // user 3
    {
        username: 'annag',
        name: 'Anna G',
        profile_image: 'https://ih.vercel.app/img/annag/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/annag/1/01.jpg',
                    'https://ih.vercel.app/img/annag/1/02.jpg',
                    'https://ih.vercel.app/img/annag/1/03.jpg',
                    'https://ih.vercel.app/img/annag/1/04.jpg',
                    'https://ih.vercel.app/img/annag/1/05.jpg',
                    'https://ih.vercel.app/img/annag/1/06.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },

            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/annag/1/05.jpg',
                    'https://ih.vercel.app/img/annag/1/01.jpg',
                    'https://ih.vercel.app/img/annag/1/02.jpg',
                    'https://ih.vercel.app/img/annag/1/03.jpg',
                    'https://ih.vercel.app/img/annag/1/04.jpg',
                    'https://ih.vercel.app/img/annag/1/06.jpg',
                    'https://ih.vercel.app/img/annag/1/07.jpg',
                    'https://ih.vercel.app/img/annag/1/08.jpg',
                    'https://ih.vercel.app/img/annag/1/09.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            }
        ]
    },


    // user 4
    {
        username: 'stacyn',
        name: 'Stacy N',
        profile_image: 'https://ih.vercel.app/img/stacyn/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-7',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/stacyn/1/01.jpg',
                    'https://ih.vercel.app/img/stacyn/1/02.jpg',
                    'https://ih.vercel.app/img/stacyn/1/03.jpg',
                    'https://ih.vercel.app/img/stacyn/1/04.jpg',
                    'https://ih.vercel.app/img/stacyn/1/05.jpg',
                    'https://ih.vercel.app/img/stacyn/1/06.jpg',
                    'https://ih.vercel.app/img/stacyn/1/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },


    // user 5
    {
        username: 'darial',
        name: 'Dasha R',
        profile_image: 'https://ih.vercel.app/img/darial/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/darial/1/01.jpg',
                    'https://ih.vercel.app/img/darial/1/02.jpg',
                    'https://ih.vercel.app/img/darial/1/03.jpg',
                    'https://ih.vercel.app/img/darial/1/04.jpg',
                    'https://ih.vercel.app/img/darial/1/05.jpg',
                    'https://ih.vercel.app/img/darial/1/06.jpg',
                    'https://ih.vercel.app/img/darial/1/07.jpg',
                    'https://ih.vercel.app/img/darial/1/08.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/darial/2/01.jpg',
                    'https://ih.vercel.app/img/darial/2/02.jpg',
                    'https://ih.vercel.app/img/darial/2/03.jpg',
                    'https://ih.vercel.app/img/darial/2/04.jpg',
                    'https://ih.vercel.app/img/darial/2/05.jpg',
                    'https://ih.vercel.app/img/darial/2/06.jpg',
                    'https://ih.vercel.app/img/darial/2/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            }
        ]
    },


    // user 6
    {
        username: 'mariab',
        name: 'Maria B',
        profile_image: 'https://ih.vercel.app/img/mariab/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-9',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/mariab/1/01.jpg',
                    'https://ih.vercel.app/img/mariab/1/02.jpg',
                    'https://ih.vercel.app/img/mariab/1/03.jpg',
                    'https://ih.vercel.app/img/mariab/1/04.jpg',
                    'https://ih.vercel.app/img/mariab/1/05.jpg',
                    'https://ih.vercel.app/img/mariab/1/06.jpg',
                    'https://ih.vercel.app/img/mariab/1/07.jpg',
                    'https://ih.vercel.app/img/mariab/1/08.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 7
    {
        username: 'delaiat',
        name: 'Maria B',
        profile_image: 'https://ih.vercel.app/img/delaiat/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/delaiat/1/01.jpg',
                    'https://ih.vercel.app/img/delaiat/1/02.jpg',
                    'https://ih.vercel.app/img/delaiat/1/03.jpg',
                    'https://ih.vercel.app/img/delaiat/1/04.jpg',
                    'https://ih.vercel.app/img/delaiat/1/05.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/delaiat/2/01.jpg',
                    'https://ih.vercel.app/img/delaiat/2/02.jpg',
                    'https://ih.vercel.app/img/delaiat/2/03.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 8
    {
        username: 'albinam',
        name: 'Maria B',
        profile_image: 'https://ih.vercel.app/img/albinam/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/albinam/1/01.jpg',
                    'https://ih.vercel.app/img/albinam/1/02.jpg',
                    'https://ih.vercel.app/img/albinam/1/03.jpg',
                    'https://ih.vercel.app/img/albinam/1/04.jpg',
                    'https://ih.vercel.app/img/albinam/1/05.jpg',
                    'https://ih.vercel.app/img/albinam/1/06.jpg',
                    'https://ih.vercel.app/img/albinam/1/07.jpg',
                    'https://ih.vercel.app/img/albinam/1/08.jpg',
                    'https://ih.vercel.app/img/albinam/1/09.jpg',
                    'https://ih.vercel.app/img/albinam/1/10.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 9
    {
        username: 'alyonar',
        name: 'Maria B',
        profile_image: 'https://ih.vercel.app/img/alyonar/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/alyonar/1/01.jpg',
                    'https://ih.vercel.app/img/alyonar/1/02.jpg',
                    'https://ih.vercel.app/img/alyonar/1/03.jpg',
                    'https://ih.vercel.app/img/alyonar/1/04.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },

    // user 10
    {
        username: 'bellag',
        name: 'Delaia T',
        profile_image: 'https://ih.vercel.app/img/bellag/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/bellag/1/01.jpg',
                    'https://ih.vercel.app/img/bellag/1/02.jpg',
                    'https://ih.vercel.app/img/bellag/1/03.jpg',
                    'https://ih.vercel.app/img/bellag/1/04.jpg',
                    'https://ih.vercel.app/img/bellag/1/05.jpg',
                    'https://ih.vercel.app/img/bellag/1/06.jpg',
                    'https://ih.vercel.app/img/bellag/1/07.jpg',
                    'https://ih.vercel.app/img/bellag/1/08.jpg',
                    'https://ih.vercel.app/img/bellag/1/09.jpg',
                    'https://ih.vercel.app/img/bellag/1/10.jpg',
                    'https://ih.vercel.app/img/bellag/1/11.jpg',
                    'https://ih.vercel.app/img/bellag/1/12.jpg',
                    'https://ih.vercel.app/img/bellag/1/13.jpg',
                    'https://ih.vercel.app/img/bellag/1/14.jpg',
                    'https://ih.vercel.app/img/bellag/1/15.jpg',
                    'https://ih.vercel.app/img/bellag/1/16.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },

    // user 11
    {
        username: 'candyt',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/candyt/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/candyt/1/01.jpg',
                    'https://ih.vercel.app/img/candyt/1/02.jpg',
                    'https://ih.vercel.app/img/candyt/1/03.jpg',
                    'https://ih.vercel.app/img/candyt/1/04.jpg',
                    'https://ih.vercel.app/img/candyt/1/05.jpg',
                    'https://ih.vercel.app/img/candyt/1/06.jpg',
                    'https://ih.vercel.app/img/candyt/1/07.jpg',
                    'https://ih.vercel.app/img/candyt/1/08.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/candyt/2/01.jpg',
                    'https://ih.vercel.app/img/candyt/2/02.jpg',
                    'https://ih.vercel.app/img/candyt/2/03.jpg',
                    'https://ih.vercel.app/img/candyt/2/04.jpg',
                    'https://ih.vercel.app/img/candyt/2/05.jpg',
                    'https://ih.vercel.app/img/candyt/2/06.jpg',
                    'https://ih.vercel.app/img/candyt/2/07.jpg',
                    'https://ih.vercel.app/img/candyt/2/08.jpg',
                    'https://ih.vercel.app/img/candyt/2/09.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 12
    {
        username: 'Chuchab',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/Chuchab/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/Chuchab/1/01.jpg',
                    'https://ih.vercel.app/img/Chuchab/1/02.jpg',
                    'https://ih.vercel.app/img/Chuchab/1/03.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 14
    {
        username: 'evgeniyab',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/evgeniyab/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/evgeniyab/1/01.jpg',
                    'https://ih.vercel.app/img/evgeniyab/1/02.jpg',
                    'https://ih.vercel.app/img/evgeniyab/1/03.jpg',
                    'https://ih.vercel.app/img/evgeniyab/1/04.jpg',
                    'https://ih.vercel.app/img/evgeniyab/1/05.jpg',
                    'https://ih.vercel.app/img/evgeniyab/1/06.jpg',
                    'https://ih.vercel.app/img/evgeniyab/1/07.jpg',
                    'https://ih.vercel.app/img/evgeniyab/1/08.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 15
    {
        username: 'katrinav',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/katrinav/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/katrinav/1/01.jpg',
                    'https://ih.vercel.app/img/katrinav/1/02.jpg',
                    'https://ih.vercel.app/img/katrinav/1/03.jpg',
                    'https://ih.vercel.app/img/katrinav/1/04.jpg',
                    'https://ih.vercel.app/img/katrinav/1/05.jpg',
                    'https://ih.vercel.app/img/katrinav/1/06.jpg',
                    'https://ih.vercel.app/img/katrinav/1/07.jpg',
                    'https://ih.vercel.app/img/katrinav/1/08.jpg',
                    'https://ih.vercel.app/img/katrinav/1/09.jpg',
                    'https://ih.vercel.app/img/katrinav/1/10.jpg',
                    'https://ih.vercel.app/img/katrinav/1/11.jpg',
                    'https://ih.vercel.app/img/katrinav/1/12.jpg',
                    'https://ih.vercel.app/img/katrinav/1/13.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 16
    {
        username: 'katyav',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/katyav/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/katyav/1/01.jpg',
                    'https://ih.vercel.app/img/katyav/1/02.jpg',
                    'https://ih.vercel.app/img/katyav/1/03.jpg',
                    'https://ih.vercel.app/img/katyav/1/04.jpg',
                    'https://ih.vercel.app/img/katyav/1/05.jpg',
                    'https://ih.vercel.app/img/katyav/1/06.jpg',
                    'https://ih.vercel.app/img/katyav/1/07.jpg',
                    'https://ih.vercel.app/img/katyav/1/08.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 17
    {
        username: 'liat',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/liat/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/liat/1/01.jpg',
                    'https://ih.vercel.app/img/liat/1/02.jpg',
                    'https://ih.vercel.app/img/liat/1/03.jpg',
                    'https://ih.vercel.app/img/liat/1/04.jpg',
                    'https://ih.vercel.app/img/liat/1/05.jpg',
                    'https://ih.vercel.app/img/liat/1/06.jpg',
                    'https://ih.vercel.app/img/liat/1/07.jpg',
                    'https://ih.vercel.app/img/liat/1/08.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 18
    {
        username: 'mayas',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/mayas/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/mayas/1/01.jpg',
                    'https://ih.vercel.app/img/mayas/1/02.jpg',
                    'https://ih.vercel.app/img/mayas/1/03.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/mayas/2/01.jpg',
                    'https://ih.vercel.app/img/mayas/2/02.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 19
    {
        username: 'milab',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/milab/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/milab/1/01.jpg',
                    'https://ih.vercel.app/img/milab/1/02.jpg',
                    'https://ih.vercel.app/img/milab/1/03.jpg',
                    'https://ih.vercel.app/img/milab/1/04.jpg',
                    'https://ih.vercel.app/img/milab/1/05.jpg',
                    'https://ih.vercel.app/img/milab/1/06.jpg',
                    'https://ih.vercel.app/img/milab/1/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 20
    {
        username: 'nastyag',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/nastyag/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/nastyag/1/01.jpg',
                    'https://ih.vercel.app/img/nastyag/1/02.jpg',
                    'https://ih.vercel.app/img/nastyag/1/03.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 21
    {
        username: 'ninah',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/ninah/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/ninah/1/01.jpg',
                    'https://ih.vercel.app/img/ninah/1/02.jpg',
                    'https://ih.vercel.app/img/ninah/1/03.jpg',
                    'https://ih.vercel.app/img/ninah/1/04.jpg',
                    'https://ih.vercel.app/img/ninah/1/05.jpg',
                    'https://ih.vercel.app/img/ninah/1/06.jpg',
                    'https://ih.vercel.app/img/ninah/1/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 22
    {
        username: 'oliviaf',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/oliviaf/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/oliviaf/1/01.jpg',
                    'https://ih.vercel.app/img/oliviaf/1/02.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/oliviaf/2/01.jpg',
                    'https://ih.vercel.app/img/oliviaf/2/02.jpg',
                    'https://ih.vercel.app/img/oliviaf/2/03.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-3',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/oliviaf/3/01.jpg',
                    'https://ih.vercel.app/img/oliviaf/3/02.jpg',
                    'https://ih.vercel.app/img/oliviaf/3/03.jpg',
                    'https://ih.vercel.app/img/oliviaf/3/04.jpg',
                    'https://ih.vercel.app/img/oliviaf/3/05.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 23
    {
        username: 'rubyr',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/rubyr/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/rubyr/1/01.jpg',
                    'https://ih.vercel.app/img/rubyr/1/02.jpg',
                    'https://ih.vercel.app/img/rubyr/1/03.jpg',
                    'https://ih.vercel.app/img/rubyr/1/04.jpg',
                    'https://ih.vercel.app/img/rubyr/1/05.jpg',
                    'https://ih.vercel.app/img/rubyr/1/06.jpg',
                    'https://ih.vercel.app/img/rubyr/1/07.jpg',
                    'https://ih.vercel.app/img/rubyr/1/08.jpg',
                    'https://ih.vercel.app/img/rubyr/1/09.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 24
    {
        username: 'sofiat',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/sofiat/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/sofiat/1/01.jpg',
                    'https://ih.vercel.app/img/sofiat/1/02.jpg',
                    'https://ih.vercel.app/img/sofiat/1/03.jpg',
                    'https://ih.vercel.app/img/sofiat/1/04.jpg',
                    'https://ih.vercel.app/img/sofiat/1/05.jpg',
                    'https://ih.vercel.app/img/sofiat/1/06.jpg',
                    'https://ih.vercel.app/img/sofiat/1/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/sofiat/2/01.jpg',
                    'https://ih.vercel.app/img/sofiat/2/02.jpg',
                    'https://ih.vercel.app/img/sofiat/2/03.jpg',
                    'https://ih.vercel.app/img/sofiat/2/04.jpg',
                    'https://ih.vercel.app/img/sofiat/2/05.jpg',
                    'https://ih.vercel.app/img/sofiat/2/06.jpg',
                    'https://ih.vercel.app/img/sofiat/2/07.jpg',
                    'https://ih.vercel.app/img/sofiat/2/08.jpg',
                    'https://ih.vercel.app/img/sofiat/2/09.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 25
    {
        username: 'stellac',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/stellac/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/stellac/1/01.jpg',
                    'https://ih.vercel.app/img/stellac/1/02.jpg',
                    'https://ih.vercel.app/img/stellac/1/03.jpg',
                    'https://ih.vercel.app/img/stellac/1/04.jpg',
                    'https://ih.vercel.app/img/stellac/1/05.jpg',
                    'https://ih.vercel.app/img/stellac/1/06.jpg',
                    'https://ih.vercel.app/img/stellac/1/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/stellac/2/01.jpg',
                    'https://ih.vercel.app/img/stellac/2/02.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-3',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/stellac/3/01.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-4',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/stellac/4/01.jpg',
                    'https://ih.vercel.app/img/stellac/4/02.jpg',
                    'https://ih.vercel.app/img/stellac/4/03.jpg',
                    'https://ih.vercel.app/img/stellac/4/04.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 26
    {
        username: 'valeriag',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/valeriag/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/valeriag/1/01.jpg',
                    'https://ih.vercel.app/img/valeriag/1/02.jpg',
                    'https://ih.vercel.app/img/valeriag/1/03.jpg',
                    'https://ih.vercel.app/img/valeriag/1/04.jpg',
                    'https://ih.vercel.app/img/valeriag/1/05.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 27
    {
        username: 'victoryc',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/victoryc/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/victoryc/1/01.jpg',
                    'https://ih.vercel.app/img/victoryc/1/02.jpg',
                    'https://ih.vercel.app/img/victoryc/1/03.jpg',
                    'https://ih.vercel.app/img/victoryc/1/04.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/victoryc/2/01.jpg',
                    'https://ih.vercel.app/img/victoryc/2/02.jpg',
                    'https://ih.vercel.app/img/victoryc/2/03.jpg',
                    'https://ih.vercel.app/img/victoryc/2/04.jpg',
                    'https://ih.vercel.app/img/victoryc/2/05.jpg',
                    'https://ih.vercel.app/img/victoryc/2/06.jpg',
                    'https://ih.vercel.app/img/victoryc/2/07.jpg',
                    'https://ih.vercel.app/img/victoryc/2/08.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 27
    {
        username: 'vladaf',
        name: 'Candy T',
        profile_image: 'https://ih.vercel.app/img/vladaf/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/vladaf/1/01.jpg',
                    'https://ih.vercel.app/img/vladaf/1/02.jpg',
                    'https://ih.vercel.app/img/vladaf/1/03.jpg',
                    'https://ih.vercel.app/img/vladaf/1/04.jpg',
                    'https://ih.vercel.app/img/vladaf/1/05.jpg',
                    'https://ih.vercel.app/img/vladaf/1/06.jpg',
                    'https://ih.vercel.app/img/vladaf/1/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 28
    {
        username: 'alice',
        name: 'Alice T',
        profile_image: 'https://ih.vercel.app/img/alice/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/alice/1/01.jpg',
                    'https://ih.vercel.app/img/alice/1/02.jpg',
                    'https://ih.vercel.app/img/alice/1/03.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/alice/2/02.jpg',
                    'https://ih.vercel.app/img/alice/2/03.jpg',
                    'https://ih.vercel.app/img/alice/2/04.jpg',
                    'https://ih.vercel.app/img/alice/2/05.jpg',
                    'https://ih.vercel.app/img/alice/2/06.jpg',
                    'https://ih.vercel.app/img/alice/2/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-3',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/alice/3/01.jpg',
                    'https://ih.vercel.app/img/alice/3/02.jpg',
                    'https://ih.vercel.app/img/alice/3/03.jpg',
                    'https://ih.vercel.app/img/alice/3/04.jpg',
                    'https://ih.vercel.app/img/alice/3/05.jpg',
                    'https://ih.vercel.app/img/alice/3/06.jpg',
                    'https://ih.vercel.app/img/alice/3/07.jpg',
                    'https://ih.vercel.app/img/alice/3/08.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 29
    {
        username: 'alla',
        name: 'Alla T',
        profile_image: 'https://ih.vercel.app/img/alla/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/alla/1/01.jpg',
                    'https://ih.vercel.app/img/alla/1/02.jpg',
                    'https://ih.vercel.app/img/alla/1/03.jpg',
                    'https://ih.vercel.app/img/alla/1/04.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 29
    {
        username: 'angelina',
        name: 'Angelina T',
        profile_image: 'https://ih.vercel.app/img/angelina/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/angelina/1/01.jpg',
                    'https://ih.vercel.app/img/angelina/1/02.jpg',
                    'https://ih.vercel.app/img/angelina/1/03.jpg',
                    'https://ih.vercel.app/img/angelina/1/04.jpg',
                    'https://ih.vercel.app/img/angelina/1/05.jpg',
                    'https://ih.vercel.app/img/angelina/1/06.jpg',
                    'https://ih.vercel.app/img/angelina/1/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 30
    {
        username: 'any',
        name: 'Any T',
        profile_image: 'https://ih.vercel.app/img/any/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/any/1/01.jpg',
                    'https://ih.vercel.app/img/any/1/02.jpg',
                    'https://ih.vercel.app/img/any/1/03.jpg',
                    'https://ih.vercel.app/img/any/1/04.jpg',
                    'https://ih.vercel.app/img/any/1/05.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/any/2/01.jpg',
                    'https://ih.vercel.app/img/any/2/02.jpg',
                    'https://ih.vercel.app/img/any/2/03.jpg',
                    'https://ih.vercel.app/img/any/2/04.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 31
    {
        username: 'fenix',
        name: 'fenix T',
        profile_image: 'https://ih.vercel.app/img/fenix/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/fenix/1/01.jpg',
                    'https://ih.vercel.app/img/fenix/1/02.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/any/2/01.jpg',
                    'https://ih.vercel.app/img/fenix/2/02.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 32
    {
        username: 'galya',
        name: 'galya T',
        profile_image: 'https://ih.vercel.app/img/galya/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/galya/1/01.jpg',
                    'https://ih.vercel.app/img/galya/1/02.jpg',
                    'https://ih.vercel.app/img/galya/1/03.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 33
    {
        username: 'karina',
        name: 'karina T',
        profile_image: 'https://ih.vercel.app/img/karina/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/karina/1/01.jpg',
                    'https://ih.vercel.app/img/karina/1/02.jpg',
                    'https://ih.vercel.app/img/karina/1/03.jpg',
                    'https://ih.vercel.app/img/karina/1/04.jpg',
                    'https://ih.vercel.app/img/karina/1/05.jpg',
                    'https://ih.vercel.app/img/karina/1/06.jpg',
                    'https://ih.vercel.app/img/karina/1/07.jpg',
                    'https://ih.vercel.app/img/karina/1/08.jpg',
                    'https://ih.vercel.app/img/karina/1/09.jpg',
                    'https://ih.vercel.app/img/karina/1/10.jpg',
                    'https://ih.vercel.app/img/karina/1/11.jpg',
                    'https://ih.vercel.app/img/karina/1/12.jpg',
                    'https://ih.vercel.app/img/karina/1/13.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 34
    {
        username: 'lyuba',
        name: 'lyuba T',
        profile_image: 'https://ih.vercel.app/img/lyuba/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/lyuba/1/01.jpg',
                    'https://ih.vercel.app/img/lyuba/1/02.jpg',
                    'https://ih.vercel.app/img/lyuba/1/03.jpg',
                    'https://ih.vercel.app/img/lyuba/1/04.jpg',
                    'https://ih.vercel.app/img/lyuba/1/05.jpg',
                    'https://ih.vercel.app/img/lyuba/1/06.jpg',
                    'https://ih.vercel.app/img/lyuba/1/07.jpg',
                    'https://ih.vercel.app/img/lyuba/1/08.jpg',
                    'https://ih.vercel.app/img/lyuba/1/09.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 35
    {
        username: 'marina',
        name: 'marina T',
        profile_image: 'https://ih.vercel.app/img/marina/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/marina/1/01.jpg',
                    'https://ih.vercel.app/img/marina/1/02.jpg',
                    'https://ih.vercel.app/img/marina/1/03.jpg',
                    'https://ih.vercel.app/img/marina/1/04.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 36
    {
        username: 'martha',
        name: 'martha T',
        profile_image: 'https://ih.vercel.app/img/martha/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/martha/1/01.jpg',
                    'https://ih.vercel.app/img/martha/1/02.jpg',
                    'https://ih.vercel.app/img/martha/1/03.jpg',
                    'https://ih.vercel.app/img/martha/1/04.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/martha/2/01.jpg',
                    'https://ih.vercel.app/img/martha/2/02.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-3',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/martha/3/01.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-4',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/martha/4/01.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 37
    {
        username: 'masha',
        name: 'masha T',
        profile_image: 'https://ih.vercel.app/img/masha/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/masha/1/01.jpg',
                    'https://ih.vercel.app/img/masha/1/02.jpg',
                    'https://ih.vercel.app/img/masha/1/03.jpg',
                    'https://ih.vercel.app/img/masha/1/04.jpg',
                    'https://ih.vercel.app/img/masha/1/05.jpg',
                    'https://ih.vercel.app/img/masha/1/06.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-2',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/masha/2/01.jpg',
                    'https://ih.vercel.app/img/masha/2/02.jpg',
                    'https://ih.vercel.app/img/masha/2/03.jpg',
                    'https://ih.vercel.app/img/masha/2/04.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-3',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/masha/3/01.jpg',
                    'https://ih.vercel.app/img/masha/3/02.jpg',
                    'https://ih.vercel.app/img/masha/3/03.jpg',
                    'https://ih.vercel.app/img/masha/3/04.jpg',
                    'https://ih.vercel.app/img/masha/3/05.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
            {
                title: 'post-4',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/masha/4/01.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 38
    {
        username: 'polina',
        name: 'polina T',
        profile_image: 'https://ih.vercel.app/img/polina/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/polina/1/01.jpg',
                    'https://ih.vercel.app/img/polina/1/02.jpg',
                    'https://ih.vercel.app/img/polina/1/03.jpg',
                    'https://ih.vercel.app/img/polina/1/04.jpg',
                    'https://ih.vercel.app/img/polina/1/05.jpg',
                    'https://ih.vercel.app/img/polina/1/06.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 39
    {
        username: 'polya',
        name: 'polya T',
        profile_image: 'https://ih.vercel.app/img/polya/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/polya/1/01.jpg',
                    'https://ih.vercel.app/img/polya/1/02.jpg',
                    'https://ih.vercel.app/img/polya/1/03.jpg',
                    'https://ih.vercel.app/img/polya/1/04.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 41
    {
        username: 'sabina',
        name: 'sabina T',
        profile_image: 'https://ih.vercel.app/img/sabina/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/sabina/1/01.jpg',
                    'https://ih.vercel.app/img/sabina/1/02.jpg',
                    'https://ih.vercel.app/img/sabina/1/03.jpg',
                    'https://ih.vercel.app/img/sabina/1/04.jpg',
                    'https://ih.vercel.app/img/sabina/1/05.jpg',
                    'https://ih.vercel.app/img/sabina/1/06.jpg',
                    'https://ih.vercel.app/img/sabina/1/07.jpg',
                    'https://ih.vercel.app/img/sabina/1/08.jpg',
                    'https://ih.vercel.app/img/sabina/1/09.jpg',
                    'https://ih.vercel.app/img/sabina/1/10.jpg',
                    'https://ih.vercel.app/img/sabina/1/11.jpg',
                    'https://ih.vercel.app/img/sabina/1/12.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 42
    {
        username: 'sofia',
        name: 'sofia T',
        profile_image: 'https://ih.vercel.app/img/sofia/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/sofia/1/01.jpg',
                    'https://ih.vercel.app/img/sofia/1/02.jpg',
                    'https://ih.vercel.app/img/sofia/1/03.jpg',
                    'https://ih.vercel.app/img/sofia/1/04.jpg',
                    'https://ih.vercel.app/img/sofia/1/05.jpg',
                    'https://ih.vercel.app/img/sofia/1/06.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
    // user 43
    {
        username: 'stefa',
        name: 'stefa T',
        profile_image: 'https://ih.vercel.app/img/stefa/1/01.jpg',
        bio: 'Hello hello.',
        postCount: 77,
        collections: 12,
        followers: 125,
        subscribed: false,
        posts: [
            {
                title: 'post-1',
                purchased: false,
                preview_images: true,
                images: [
                    'https://ih.vercel.app/img/stefa/1/01.jpg',
                    'https://ih.vercel.app/img/stefa/1/02.jpg',
                    'https://ih.vercel.app/img/stefa/1/03.jpg',
                    'https://ih.vercel.app/img/stefa/1/04.jpg',
                    'https://ih.vercel.app/img/stefa/1/05.jpg',
                    'https://ih.vercel.app/img/stefa/1/06.jpg',
                    'https://ih.vercel.app/img/stefa/1/07.jpg',
                ],
                purchases: 100,
                views: 100,
                caption: 'A 3d puzzle of a raccoon',
                price: 2000,
            },
        ]
    },
]

export { data }
