import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from "@mui/material/styles";

export default function SearchNav(props) {
    const [values, setValues] = React.useState({ search: '' });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const CustomOutlinedInput = styled(OutlinedInput)({
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: '1px solid pink',
          },
          "& .MuiSvgIcon-root": {
            color: 'pink',
            },
        }
      });

      
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar color='light' elevation={0}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <FormControl variant="outlined" fullWidth>
                            <CustomOutlinedInput
                                id="search"
                                type={'search'}
                                value={values.search}
                                onChange={handleChange('search')}
                                size="small"
                                placeholder="Search"
                                sx={{borderRadius: 3}}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{color: "secondary.iconColor" }}/>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar />
        </React.Fragment>
    );
}
