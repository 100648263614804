import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import PersonIcon from '@mui/icons-material/Person';
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";


const  SettingsMenu = ({ openSettingsMenu, onSettingsClose }) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();


    useEffect(() => {
        setAnchorEl(openSettingsMenu);
    })

    const handleClose = () => {
        onSettingsClose(null)
    };

    const handleLogout = () => {
        localStorage.setItem("login", "false");
        navigate('/login')
    }

    const navigateToAccount = () => {
        navigate('/account')
    }


    return (
        <Menu
                    id="fade-menu"
                    MenuListProps={{
                        "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    style = {{marginTop: "12px"}}
                >
                    <MenuItem>
                        {" "}
                        <ListItemIcon>
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Profile
                        </Typography>{" "}
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                        {" "}
                        <ListItemIcon>
                            <BookmarkBorderOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Saved
                        </Typography>{" "}
                    </MenuItem>


                    <MenuItem  onClick={navigateToAccount}>
                        <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Settings
                        </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                       Logout
                    </MenuItem>

                </Menu>
    );
}

export default SettingsMenu;