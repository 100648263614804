import {
  CardMedia,
  Grid,
  Slide,
  Box,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Story from "../../Components/Story";
import Creators from "../../Components/Creators";
import BreadCrumb from "../../Components/BreadCrumb";
import { getAllCreators } from "../../utils/posts";
import { getAllTrendingCollections } from "../../utils/posts";
import { useNavigate } from "react-router-dom";
import useMatchMedia from "../../utils/useMatchMedia";
import { makeStyles } from "@mui/styles";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
const useStyles = makeStyles((theme) => ({
  overlayContent: {
    position: "absolute",
    cursor: "pointer",
  },

  overlayHeaderName: {
    position: "absolute",
    top: "14px",
    left: "42px",
    fontSize: "10px",
    fontFamily: "Avenir",
    fontWeight: "800",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1rem",
      left: "55px",
    },
  },
  overlayAvatar: {
    position: "absolute",
    top: "8px",
    left: "5px",
    height: "30px",
    width: "30px",
    [theme.breakpoints.up("xl")]: {
      height: "2.375rem",
      width: "2.375rem",
    },
  },
  settings: {
    position: "absolute",
    top: "1rem",
    right: 0,
    [theme.breakpoints.up("xl")]: {
      top: "1.3rem",
    },
  },
  multiplePostIcon: {
    position: "absolute",
    top: 0,
    right: 0,
  },

  imageCard: {
    height: "124px",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      height: "180px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "254px",
    },
  },

  discoverContainer: {
    marginBottom: "50px",
    [theme.breakpoints.up("sm")]: {
      padding: "10px",
    },
  },
}));

const DiscoverPage = ({ navType }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const isWebResolution = useMatchMedia("(min-width:640px)", true);
  const [showCreators, setShowCreators] = React.useState(true);
  const [showCollections, setShowCollections] = React.useState(true);
  const [creators, setCreators] = useState([]);
  const [trendingCollections, setTrendingCollections] = useState([]);

  useEffect(() => {
    navType("discover");
    setTrendingCollections(getAllTrendingCollections());
    setCreators(getAllCreators());
  }, []);

  const handleShowResult = (search, show) => {
    if (show === "all") {
      setShowCreators(true);
      setShowCollections(true);
      return;
    }

    if (search === "creators") {
      setShowCreators(true);
      setShowCollections(false);
      return;
    }
    if (search === "collections") {
      setShowCreators(false);
      setShowCollections(true);
      return;
    }
  };

  const handleNavigate = (e, user) => {
    e.stopPropagation();
    navigate(`/profile/${user}`);
  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <div className={classes.discoverContainer}>
        {!showCollections && showCreators && (
          <>
            <BreadCrumb
              title="Top Creators"
              positionLeft={!showCollections}
              name="creators"
              handleShowClick={handleShowResult}
              style={{ color: "primary" }}
            />
            <Creators list={creators} />
          </>
        )}

        {showCollections && !showCreators && (
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <div>
              <BreadCrumb
                title="Trending Collections"
                positionLeft={!showCreators}
                name="collections"
                handleShowClick={handleShowResult}
              />

              <Grid container spacing={0.1} paddingTop="6px">
                {trendingCollections.map((data, index) => (
                  <Grid item xs={4} key={index} sx={{ position: "relative" }}>
                    {!isWebResolution && data.images.length > 1 && (
                      <IconButton
                        aria-label="settings"
                        className={classes.multiplePostIcon}
                      >
                        <FilterNoneIcon
                          sx={{ color: "#F9C7C4", fontSize: "16px" }}
                        />
                      </IconButton>
                    )}
                    <CardMedia
                      component="img"
                      className={classes.imageCard}
                      image={data.image}
                      onClick={() => {
                        navigate(`/profile/${data?.user}/${data.post}`);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </Slide>
        )}

        {showCollections && showCreators && (
          <Slide direction="right" in={true} mountOnEnter unmountOnExit>
            <div>
              <div>
                {!isWebResolution && (
                  <BreadCrumb
                    title="Top Creators"
                    positionLeft={!showCollections}
                    name="creators"
                    handleShowClick={handleShowResult}
                    style={{ color: "primary" }}
                  />
                )}

                <Story
                  list={creators}
                  slidesPerView={isWebResolution ? "10" : "auto"}
                />
              </div>
              <div>
                {!isWebResolution && (
                  <BreadCrumb
                    title="Trending Collections"
                    positionLeft={!showCreators}
                    name="collections"
                    handleShowClick={handleShowResult}
                  />
                )}
                <Grid
                  container
                  spacing={isWebResolution ? "8" : "0.1"}
                  paddingTop="6px"
                  marginTop="6px"
                  columns={isWebResolution ? "10" : "12"}
                >
                  {trendingCollections &&
                    trendingCollections.map((data, index) => {
                      return (
                        <Grid
                          item
                          xs={isWebResolution ? "2" : "4"}
                          key={index}
                          sx={{ position: "relative" }}
                        >
                          {isWebResolution && (
                            <Box
                              className={classes.overlayContent}
                              onClick={(e) => {
                                handleNavigate(e, data.user);
                              }}
                            >
                              <Avatar
                                aria-label="model"
                                src={data.profile_image}
                                className={classes.overlayAvatar}
                              />

                              <Typography
                                variant="p"
                                color="#F9C7C4"
                                className={classes.overlayHeaderName}
                              >
                                {data.user}
                              </Typography>
                            </Box>
                          )}
                          {isWebResolution && (
                            <IconButton
                              aria-label="settings"
                              className={classes.settings}
                            >
                              <MoreHorizOutlinedIcon
                                sx={{ color: "#F9C7C4", fontSize: "0.8rem" }}
                              />
                            </IconButton>
                          )}

                          {!isWebResolution && data.images.length > 1 && (
                            <IconButton
                              aria-label="settings"
                              className={classes.multiplePostIcon}
                            >
                              <FilterNoneIcon
                                sx={{ color: "#F9C7C4", fontSize: "16px" }}
                              />
                            </IconButton>
                          )}
                          <CardMedia
                            onClick={() => {
                              navigate(`/profile/${data?.user}/${data.post}`);
                            }}
                            component="img"
                            className={classes.imageCard}
                            image={data.image}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            </div>
          </Slide>
        )}
      </div>
    </Slide>
  );
};

export default DiscoverPage;
