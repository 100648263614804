import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ModalPopover from "../Modal";

export default function ProfileNav(props) {
    const navigate = useNavigate();
    const [openSettingsPopover, setOpenSettingsPopover] = useState(false);

    const handleCloseConfirmPopOver = () => {
        setOpenSettingsPopover(false);
      };

    const handleGoBack = () => {
        navigate(-1);
    }
    
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar color='light' elevation={0}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <IconButton
                            sx={{ display: { md: 'flex' } }}
                            style={{ mr: 2 }}
                            onClick={() => handleGoBack()}
                        >
                            <ArrowBackIosIcon color = "primary"/>
                        </IconButton>

                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h3" component="span" style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                {props?.profileData}
                            </Typography>
                        </Box>

                        <Box sx={{ display: { md: 'flex' } }}>
                            <IconButton aria-label="notifications">
                                <NotificationsOutlinedIcon fontSize='medium' color = "primary"/>
                            </IconButton>
                            <IconButton aria-label="settings" onClick={() => setOpenSettingsPopover(true)}>
                                <MoreHorizOutlinedIcon sx={{color: "secondary.iconColor" }} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar />
            <ModalPopover open={openSettingsPopover} onClose={() => handleCloseConfirmPopOver()}/>
        </React.Fragment>
    );
}
