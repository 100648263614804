import * as React from 'react';
import { Avatar, Box, Card, Grid, Typography, Slide } from "@mui/material"
import { useNavigate } from "react-router-dom";


const Creators = ({ list }) => {
    const navigate = useNavigate();
    const handleNavigate = (e, username) => {
        e.stopPropagation();
        navigate(`/profile/${username.username}`, { replace: true });
    }

    return (
        <Slide
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
        >
            <div>
                <Card elevation={0} sx={{ padding: 1 }}>
                    <Grid container spacing={2}>
                        {
                            list.map((item, index) => (
                                <Grid item xs={3} key={index}>
                                    <Box style={{
                                        textAlign: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                        key={index}>
                                        <Avatar
                                            alt="Remy Sharp 3"
                                            src={item?.profile_image}
                                            sx={{ width: 76, height: 76 }}
                                            style={{
                                                border: localStorage.getItem("mode") === "dark" ? "" : "2px solid #DCDCDC",
                                                borderRadius: '50%',
                                            }}
                                            onClick={(e) => {
                                                handleNavigate(e, item?.username);
                                            }}
                                        />
                                        <Typography variant="p" style={{
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            color: 'primary',
                                        }}
                                            onClick={(e) => {
                                                handleNavigate(e, item?.username);
                                            }}
                                        >
                                            {item?.username}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Card>
            </div>
        </Slide>

    )
}

export default Creators;