import { Button, Card, Typography, Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({}));
export default function UserDescription({ data }) {
  const useStyles = makeStyles((theme) => ({
    subscribeBtn: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.up("sm")]: {
        border: `1px solid ${theme.palette.pink.main}`,
      },
    },
    subscribeText: {
      fontSize: "15px",
      fontWeight: "700",
      color: "secondary",
      [theme.breakpoints.up("sm")]: {
        color: theme.palette.pink.main,
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box
      elevation={0}
      sx={{ border: "none", paddingX: "1rem" }}
      style={{ borderRadius: "0" }}
    >
      <Typography gutterBottom variant="h6" component="div">
        {data.name}
      </Typography>
      <Typography variant="subtitle1" component="div">
        {data.bio}
      </Typography>

      <Typography>
        <Link
          href="#"
          underline="none"
          variant="subtitle1"
          style={{
            color: "pink",
          }}
        >
          Link goes here
        </Link>
      </Typography>

      <Box paddingY={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="pink"
              fullWidth
              style={{ boxShadow: "none" }}
            >
              <Typography
                variant="p"
                style={{
                  fontSize: "15px",
                  fontWeight: "700",
                  color: "#121826",
                }}
              >
                Follow
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              className={classes.subscribeBtn}
              fullWidth
            >
              <Typography variant="p" className={classes.subscribeText}>
                Subscribe
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
