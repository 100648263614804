import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Box, Typography, Link } from "@mui/material";
import referBanner from "../../assets/img/referBanner.png";
import twitterIcon from "../../assets/img/twitter.svg";
import facebookIcon from "../../assets/img/facebook.svg";
import messengerIcon from "../../assets/img/messenger.svg";
import shareIcon from "../../assets/img/shareLink.svg";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import useMatchMedia from "../../utils/useMatchMedia";
import ReferMbPage from "./ReferMbView";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
    marginTop: "50px",
  },
  upperSection: {
    backgroundImage: `url(${referBanner})`,
    width: "100%",
    height: "379px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },
  contentText: {
    position: "absolute",
    top: "45%",
    left: "31%",
    zIndex: "10",
    color: "#FFF",
  },

  headerText: {
    textAlign: "center",
    fontSize: "32px",
    lineHeight: "48px",
    fontWeight: "700",
  },
  infoText: {
    fontSize: "18px",
    fontFamily: "Inter !important",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  secondSection: {
    padding: "30px !important",
    marginBottom: "20px",
    fontFamily: "Inter !important",
    color: theme.palette.referPage.textColor,
    backgroundColor: theme.palette.referPage.bodyBgColor,
    boxShadow: "0px 4px 15px rgb(0 0 0 / 12%)",
  },
  linkSection: {
    width: "58%",
    margin: "auto",
    textAlign: "center",
  },
  termsAndCondition: {
    marginTop: "13px !important",
    marginBottom: "40px !important",
  },

  termsAndConditionText: {
    color: theme.palette.referPage.textColor,
  },

  socialIcons: {
    display: "flex",
    gap: "45px",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "50px",
  },

  icon: {
    width: "36px",
    height: "36px",
    display: "inline-block",
    backgroundColor: theme.palette.referPage.iconColor,
  },

  facebook: {
    "-webkit-mask": `url(${facebookIcon}) no-repeat 50% 50%`,
    "-webkit-mask-size": "cover",
  },

  twitter: {
    "-webkit-mask": `url(${twitterIcon}) no-repeat 50% 50%`,
    "-webkit-mask-size": "cover",
  },

  share: {
    "-webkit-mask": `url(${shareIcon}) no-repeat 50% 50%`,
    "-webkit-mask-size": "cover",
  },
  messenger: {
    "-webkit-mask": `url(${messengerIcon}) no-repeat 50% 50%`,
    "-webkit-mask-size": "cover",
  },
  personalLinkInput: {
    marginTop: "10px",
  },
  voucherTextFieldInputProps: {
    padding: "inherit !important",
    paddingLeft: "12px !important",
  },
}));

const ReferPage = ({ navType, header }) => {
  const classes = useStyles();
  const isWebResolution = useMatchMedia("(min-width:640px)", true);

  useEffect(() => {
    navType("refer");
  }, []);
  return (
    <>
      {!isWebResolution ? (
        <ReferMbPage navType={navType} header={header} />
      ) : (
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12} className={classes.upperSection}>
            <Box className={classes.contentText}>
              <Typography className={classes.headerText}>
                Refer & Earn!{" "}
              </Typography>
              <Typography className={classes.infoText}>
                Get ONLY rewards for every referral you make!
              </Typography>
            </Box>
            <Box className={classes.overlay}></Box>
          </Grid>
          <Grid item xs={12} className={classes.secondSection}>
            <Box className={classes.linkSection}>
              <Typography variant="p">
                Get your referral link to give your friends a $20 discount when
                they shop at Hot Beans. If they use it, we’ll reward you with
                $20.
              </Typography>
              <Box className={classes.termsAndCondition}>
                <Link>
                  <Typography
                    variant="h6"
                    component="div"
                    className={classes.termsAndConditionText}
                  >
                    Terms & Conditions
                  </Typography>
                </Link>
              </Box>
              <Typography variant="p">Invite now using:</Typography>
              <Box className={classes.socialIcons}>
                <i className={`${classes.twitter} ${classes.icon}`}></i>
                <i className={`${classes.facebook} ${classes.icon}`}></i>
                <i className={`${classes.messenger} ${classes.icon}`}></i>
                <i className={`${classes.share} ${classes.icon}`}></i>
              </Box>

              <Typography variant="p">Or copy your personal link</Typography>
              <Box>
                <TextField
                  id="filled-adornment-password"
                  placeholder="voucherify.io"
                  fullWidth
                  variant="filled"
                  className={classes.personalLinkInput}
                  InputProps={{
                    classes: {
                      input: classes.voucherTextFieldInputProps,
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          backgroundColor: "#F9C7C4",
                          height: 55,
                          width: 62,
                          marginRight: "-13px",
                        }}
                      >
                        <InputAdornment position="end">
                          <IconButton
                            size="large"
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <FilterNoneIcon
                              style={{ color: "rgba(18, 24, 38, 0.8" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      </div>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ReferPage;
