import React, { useEffect } from "react";
import { Grid, List, ListItem, ListItemText, Slide } from "@mui/material";
import Pages from "../../../Components/Pages";
import { makeStyles } from "@mui/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  listIcon: {
    color: "primary",
    height: "30px",
    width: "30px",
  },

  listItem: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    paddingTop: "15px",
    paddingBottom: "15px",
  },

  text: {
    fontWeight: "500 !important",
    fontFamily: "Avenir !important",
  },
}));

const AccountLanguagePage = ({ navType, header }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    navType("accountPages");
    header("Settings");
  }, []);

  return (
    <Pages>
      <Slide
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
      >
        <Grid item xs={12}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.list}
          >
            <ListItem button className={classes.listItem} onClick={() => navigate('/account/setting/profile/edit')}>
              <ListItemText
                classes={{ primary: classes.text }}
                primary="My Profile"
              />
              <ChevronRightIcon className={classes.listIcon} />
            </ListItem>
            <Divider />
            <ListItem button className={classes.listItem} onClick={() => navigate('/account/info')}>
              <ListItemText
                classes={{ primary: classes.text }}
                primary="Accounts"
              />
              <ChevronRightIcon className={classes.listIcon} />
            </ListItem>
            <Divider />
            <ListItem button className={classes.listItem}>
              <ListItemText
                classes={{ primary: classes.text }}
                primary="Privacy and Safety"
              />
              <ChevronRightIcon className={classes.listIcon} />
            </ListItem>
            <Divider />

            <ListItem button className={classes.listItem}>
              <ListItemText
                classes={{ primary: classes.text }}
                primary="Notifications"
              />
              <ChevronRightIcon className={classes.listIcon} />
            </ListItem>
            <Divider />

            <ListItem button className={classes.listItem}>
              <ListItemText
                classes={{ primary: classes.text }}
                primary="Display"
              />
              <ChevronRightIcon className={classes.listIcon} />
            </ListItem>
            <Divider />
          </List>
        </Grid>
      </Slide>
    </Pages>
  );
};

export default AccountLanguagePage;
