import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate, useParams } from "react-router-dom";
import useMatchMedia from "../../utils/useMatchMedia";
import { makeStyles } from "@mui/styles";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Pagination } from "swiper";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    cursor: "pointer",
  },

  imageAvatar: {
    display: "block",
    width: "4.5rem",
    height: "4.5rem",
    borderRadius: "50%",
    cursor: "pointer",
    [theme.breakpoints.up("xl")]: {
      width: "6rem",
      height: "6rem",
    },
  },

  overlay: {
    width: "4.5rem",
    height: "4.5rem",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.6,
    backgroundColor: "#000",
    borderRadius: "50%",
    transition: ".5s ease",
    [theme.breakpoints.up("xl")]: {
      width: "6rem",
      height: "6rem",
    },
  },

  creatorName: {
    fontSize: "10px",
    fontWeight: "800",
    color: "pink",
    position: "absolute",
    fontFamily: "Avenir",
    cursor: "pointer",
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px",
    },
  },
  profileCreatorName: {
    marginTop: "5px",
    fontSize: "12px",
  },
  imageProfileAvatar: {
    width: 60,
    height: 60,
  },
}));

const Story = ({ list, source = "", slidesPerView = "" }) => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const classes = useStyles();
  const [showName, setShowName] = useState(null);
  const isWebResolution = useMatchMedia("(min-width:640px)", true);
  const handleNavigate = (e, username) => {
    e.stopPropagation();
    if (routeParams.username != username) {
      navigate(`/profile/${username.username}`);
    }
  };

  const handleShowName = (event, index) => {
    event.stopPropagation();
    if (source !== "profile") setShowName(index);
  };

  const handleHideName = (event) => {
    event.stopPropagation();
    if (source !== "profile") setShowName(null);
  };

  return (
    <Box sx={{ border: "none", pt: 3, pb: 2 }} style={{ borderRadius: "0" }}>
      <Swiper
        pagination={false}
        modules={[Pagination]}
        slidesPerView={slidesPerView ? slidesPerView : "auto"}
        className="mySwiper"
        breakpoints={{
          0: {
            spaceBetween: -10,
          },
          576: {
            spaceBetween: -10,
          },
          1024: {
            spaceBetween: 50,
          },
          1400: {
            spaceBetween: 80,
          },
        }}
      >
        {list.map((item, index) => (
          <SwiperSlide
            key={index}
            className="story-content"
            onMouseLeave={(e) => handleHideName(e)}
          >
            <Box
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className={classes.container}
                onClick={(e) => {
                  handleNavigate(e, item);
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={item?.profile_image}
                  style={{
                    border:
                      localStorage.getItem("mode") === "dark" &&
                      source != "profile"
                        ? ""
                        : "2px solid #DCDCDC",
                    borderRadius: "50%",
                  }}
                  className={
                    source == "profile"
                      ? classes.imageProfileAvatar
                      : classes.imageAvatar
                  }
                  onMouseEnter={(e) => handleShowName(e, index)}
                />
                {isWebResolution &&
                  showName == index &&
                  source !== "profile" && (
                    <div className={classes.overlay}></div>
                  )}
              </div>
              {isWebResolution && showName == index && source !== "profile" && (
                <Typography
                  variant="p"
                  className={classes.creatorName}
                  onClick={(e) => {
                    handleNavigate(e, item);
                  }}
                >
                  {item?.username}
                </Typography>
              )}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Story;
