import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    breadcrumbTitle : {
        fontSize: '13px',
                    fontWeight: '400',
                    color: 'primary',
        [theme.breakpoints.up('xl')]: {
            fontSize: '22px'
        },
    }
  }));
  
const BreadCrumb = ({ title, positionLeft, handleShowClick, name }) => {
    const classes = useStyles();

    return (
        <Box paddingX={1} paddingTop={1}>
            <Box style={{
                display: 'flex',
                alignItems: 'center',
            }}
                onClick={() => {
                    positionLeft ? handleShowClick(name, 'all') : handleShowClick(name, 'single');
                }}>
                {
                    positionLeft && <ArrowBackIosIcon sx={{ fontSize: 14, marginRight: "4px" }}/>
                }
                <Typography variant='p' 

                className = {classes.breadcrumbTitle}>
                    {title}
                </Typography>
                {
                    !positionLeft && <ArrowForwardIosIcon sx={{ fontSize: 14 , marginLeft: "4px"}} />
                }
            </Box>
        </Box>
    )
}

export default BreadCrumb;