import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Logo from '../../assets/img/logo.svg';
import PinkLogo from "../../assets/img/pinklogo.svg";
import EventBus from "../../core/EventBus";
import { useNavigate } from "react-router-dom";

export default function AccountNav(props) {

const [darkMode, setDarkMode] = useState(false);
const navigate = useNavigate();

  useEffect(() => {
    EventBus.on("setTheme", (theme) => {
      if (theme) {
        if (theme == "dark") {
          setDarkMode(true);
        } else {
          setDarkMode(false);
        }
      }
    });
    if (localStorage.getItem("mode") === "dark") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  const navigateToNotification = () => {
    navigate("/notification")
  }


    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar color='light' elevation={0}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <IconButton sx={{ display: { md: 'flex' } }}>
                        {darkMode ? <img src={PinkLogo} alt="logo" /> :<img src={Logo} alt="logo" />}
                        </IconButton>

                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ display: { md: 'flex' } }}>
                            <IconButton 
                                size="large" 
                                edge="end" 
                                aria-label="notification" 
                                color="inherit" 
                                sx={{ mr: "2px" }}
                            >
                                <NotificationsOutlinedIcon sx={{ fontSize: "24x", color: "secondary.iconColor" }} />
                            </IconButton>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="likes"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <FavoriteBorderIcon 
                                  sx={{ fontSize: "24px" , color: "secondary.iconColor"}} 
                                  onClick = {navigateToNotification}
                                />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar />
        </React.Fragment>
    );
}
