import React, { useEffect } from "react";
import { Grid, Avatar, Typography, Slide } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Pages from "../../../../Components/Pages";
import AvatarImage from "../../../../assets/img/avatar.png";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  listIcon: {
    color:
      localStorage.getItem("mode") == "dark" ? "rgba(18, 24, 38, 0.8)" : "",
    height: "35px",
    width: "35px",
  },

  listItem: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  text: {
    marginBottom: "0px !important",
  },
}));

const AccountLanguagePage = ({ navType, header }) => {
  const classes = useStyles();

  useEffect(() => {
    navType("accountPages");
    header("Edit Profile");
  }, []);

  return (
    <Pages>
      <Slide
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
      >
        <Grid item xs={12}>
          <Box sx={{ position: "relative" }}>
            <Avatar
              alt="Remy Sharp"
              src={AvatarImage}
              sx={{ width: 115, height: 115 }}
              style={{
                border:
                  localStorage.getItem("mode") === "dark"
                    ? ""
                    : "2px solid #DCDCDC",
                borderRadius: "50%",
                margin: "auto",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: "0",
                left: "38%",
                color: "white",
                padding: "10px",
                height: "100%",
                width: "111px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background:
                  localStorage.getItem("mode") == "dark"
                    ? "rgba(217, 217, 217, 0.5)"
                    : "rgba(0, 0, 0, 0.5)",
              }}
            >
              <CameraAltOutlinedIcon className={classes.listIcon} />
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "10px",
              marginBottom: "30px",
            }}
          >
            <p className={classes.text}>Username</p>
            <TextField
              fullWidth
              hiddenLabel
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: { fontWeight: 300, fontStyle: 'normal', fontSize: '16px' }
              }}
              defaultValue="@AnungJanatara"
              style={{ padding: "10px 10px !important" }}
            />
            <p className={classes.text}>Display name</p>
            <TextField
              fullWidth
              hiddenLabel
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: { fontWeight: 300, fontStyle: 'normal', fontSize: '16px' }
              }}
              defaultValue="Anung Janatara"
            />
            <p className={classes.text}>Bio</p>
            <TextField
              fullWidth
              hiddenLabel
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: { fontWeight: 300, fontStyle: 'normal', fontSize: '16px' }
              }}
              defaultValue="Just a girl who likes girl"
            />
            <p className={classes.text}>Location</p>
            <TextField
              fullWidth
              hiddenLabel
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: { fontWeight: 300, fontStyle: 'normal', fontSize: '16px' }
              }}
              defaultValue="New York, USA"
            />
          </Box>
          <Button
            variant="contained"
            color="pink"
            fullWidth
            style={{
              boxShadow: "none",
              marginBottom: "20px",
              padding: "10px 0px",
            }}
          >
            <Typography
              variant="p"
              style={{
                fontSize: "15px",
                fontWeight: "700",
                color: "#121826",
              }}
            >
              Save
            </Typography>
          </Button>
        </Grid>
      </Slide>
    </Pages>
  );
};

export default AccountLanguagePage;
