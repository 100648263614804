import React from "react";
import BottomBar from "../BottomBar";
import Navbar from "../Navbar";
import useMatchMedia from "../../utils/useMatchMedia";
import ProfileNav from "../Navbar/profile";
import SearchNav from "../Navbar/search";
import AccountNav from "../Navbar/account";
import AccountPagesNav from "../Navbar/accountPages";
import { useLocation } from "react-router-dom";
import MessagePage from "../../views/MessagePage";
import WebNav from "./webnav";
import MessageNav from "../Navbar/message";

const Layouts = ({ children, navType, profileData, header }) => {
  const isMobileResolution = useMatchMedia("(min-width:640px)", true);
  const location = useLocation();

  return (
    <>
      {location.pathname === "/login" || location.pathname === "/register" ? (
        <>{children}</>
      ) : (
        <div className="">
          {navType === "profile" &&
            (isMobileResolution ? (
              <WebNav profileData={profileData} />
            ) : (
              <ProfileNav profileData={profileData} />
            ))}

          {navType === "navbar" &&
            (isMobileResolution ? <WebNav /> : <Navbar />)}

          {navType === "discover" &&
            (isMobileResolution ? <WebNav /> : <SearchNav />)}

          {navType === "account" &&
            (isMobileResolution ? <WebNav /> : <AccountNav />)}

          {navType === "accountPages" && <AccountPagesNav header={header} />}
          {navType === "notification" && <AccountPagesNav header={header} />}
          {navType === "inbox" &&
            (isMobileResolution ? (
              <WebNav />
            ) : (
              <MessageNav profileData={profileData} />
            ))}
          {navType === "refer" && isMobileResolution && <WebNav />}
          {children}
          {isMobileResolution ? "" : <BottomBar />}
        </div>
      )}
    </>
  );
};

export default Layouts;
