import { Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Feed from '../../Components/Feed';
import { getAllPostDataByUser } from '../../utils/posts'
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme)=>({
  discoverFeed: {
  [theme.breakpoints.up('sm')]: {
    width: '50%',
    margin: 'auto',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    marginTop: "40px"
  },
}
})
)
const DiscoverPosts = ({ navType }) => {
  const [data, setData] = useState([]);
  const { username, post } = useParams();
  const classes = useStyles();

  const scrollTo = (ref) => {
    const section = document.getElementById(`${ref}`);
    if (section)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  useEffect(() => {
    navType('navbar');
    setData(getAllPostDataByUser(username))
  }, []);

  useEffect(() => {
    if (post)
      scrollTo(post)
  });


  return (
    <Slide
      direction="right"
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <div style={{
        marginBottom: '50px',
      }}>
        {data.posts && data.posts.map((post, index) => {
          return (
            <div className={classes.discoverFeed}>
              <Feed key={index} isFeed={true} post={post} user={{ username: data.username, name: data.name, profile_image: data.profile_image, bio: data.bio, subscribed: data.subscribed }} />
            </div>
          )
        })
        }
      </div>
    </Slide>
  );
};

export default DiscoverPosts;
