import Popover from "@mui/material/Popover";
import { List, ListItem, ListItemText, Paper, Stack } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import LinkIcon from "@mui/icons-material/Link";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  actions: {
    bottom: "0px !important",
    left: "0px !important",
    position: "fixed",
    maxHeight: "fit-content",
    maxWidth: "inherit",
    top: "initial !important",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    fontSize: "14px !important",
  },

  list: {
    padding: "0px !important",
  },
  itemText: {
    textAlign: "center",
  },
  listItem: {
    borderBottom: "0.5px solid lightgray",
  },
  actionButton: {
    flexDirection: "column",
    padding: "10px",
    height: "40%",
    width: "30%",
    borderRadius: "10px",
  },
});
export default function ModalPopover(open, onClose) {
  const classes = useStyles();
  return (
    <Popover
      open={open.open}
      onClose={open.onClose}
      anchorReference="anchorPosition"
      anchorPosition={{ bottom: 0 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      PaperProps={{
        style: { width: "100%" },
        classes: { root: classes.actions },
      }}
    >
      <div style={{ display: "flex", margin: "30px 20px 15px 20px" }}>
        <Button className={classes.actionButton} style={{background: localStorage.getItem("mode") === "dark" ? "#363636" : "#e3e3e3", marginRight: "5%" }}>
          <IosShareIcon />
          Share
        </Button>

        <Button className={classes.actionButton} style={{background: localStorage.getItem("mode") === "dark" ? "#363636" : "#e3e3e3", marginRight: "5%" }}>
          <LinkIcon />
          Link
        </Button>

        <Button className={classes.actionButton} style={{background: localStorage.getItem("mode") === "dark" ? "#363636" : "#e3e3e3", color: "red" }}>
          <ReportGmailerrorredIcon />
          Report
        </Button>
      </div>
      <div
        style={{
          background: localStorage.getItem("mode") === "dark" ? "#363636" : "#e3e3e3",
          margin: "15px 20px",
          borderRadius: "10px",
        }}
      >
        <List aria-label="actions" className={classes.list}>
          <ListItem button>
            <ListItemText
              className={classes.itemText}
              primary="Why you are seeing this post"
            />
          </ListItem>
        </List>
      </div>
      <div
        style={{
          background: localStorage.getItem("mode") === "dark" ? "#363636" : "#e3e3e3",
          margin: "15px 20px",
          borderRadius: "10px",
        }}
      >
        <List aria-label="actions" className={classes.list}>
          <ListItem className={classes.listItem} button>
            <ListItemText
              className={classes.itemText}
              primary="Add to Favourites"
            />
          </ListItem>
          <ListItem className={classes.listItem} button>
            <ListItemText
              className={classes.itemText}
              primary="About this account"
            />
          </ListItem>
          <ListItem className={classes.listItem} button>
            <ListItemText className={classes.itemText} primary="Hide" />
          </ListItem>
          <ListItem button>
            <ListItemText className={classes.itemText} primary="Unfollow" />
          </ListItem>
        </List>
      </div>
    </Popover>
  );
}
