import React, { useEffect } from "react";
import { Grid, Avatar, Typography, Slide } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Pages from "../../../../Components/Pages";
import MenuItem from '@mui/material/MenuItem';
import USIcon from '../../../../assets/img/usa.png'

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  listIcon: {
    color:
      localStorage.getItem("mode") == "dark" ? "rgba(18, 24, 38, 0.8)" : "",
    height: "35px",
    width: "35px",
  },

  listItem: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  text: {
    fontWeight: "300",
    marginBottom: "0px !important",
  },
}));

const currencies = [
  {
    value: "US",
    label: `${USIcon}`,
  },
  {
    value: "EU",
    label: `${USIcon}`,
  },
];

const AccountLanguagePage = ({ navType, header }) => {
  const classes = useStyles();
  const [currency, setCurrency] = React.useState("EU");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  useEffect(() => {
    navType("accountPages");
    header("Change Phone Number");
  }, []);

  return (
    <Pages>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Grid item xs={12}>
          <Box
            component="form"
            sx={{ display: 'flex' }}
            noValidate
            autoComplete="off"
          >
            {/* <TextField
              id="outlined-select-currency"
              select
              hiddenLabel
              variant="filled"
              value={currency}
              onChange={handleChange}
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRight: '0px',
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                },
              }}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <img src={option.label} height="15px"/>
                </MenuItem>
              ))}
            </TextField> */}
            <TextField
              hiddenLabel
              fullWidth
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: {
                  fontWeight: 300,
                  fontStyle: "normal",
                  fontSize: "16px",
                  borderLeft: '0px',
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                },
              }}
              defaultValue="+1 123 456 7890"
              style={{ padding: "10px 10px !important" }}
            />
          </Box>
          <Button
            variant="contained"
            color="pink"
            fullWidth
            style={{
              boxShadow: "none",
              marginBottom: "20px",
              marginTop: "30px",
              padding: "10px 0px",
            }}
          >
            <Typography
              variant="p"
              style={{
                fontSize: "15px",
                fontWeight: "700",
                color: "#121826",
              }}
            >
              Save
            </Typography>
          </Button>
        </Grid>
      </Slide>
    </Pages>
  );
};

export default AccountLanguagePage;
