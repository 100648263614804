import { Avatar, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";

export default function UserInfo({ data }) {
  return (
    <Box
      sx={{ display: "flex", padding: "1rem" }}
      style={{ borderRadius: "0" }}
    >
      <Avatar
        alt="Anna"
        src={data.profile_image}
        sx={{ width: 72, height: 72 }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pl: 2,
          }}
        >
          <Box
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="h4" fontWeight={800}>
              {data.postCount}
            </Typography>
            <Typography variant="subtitle1">Posts</Typography>
          </Box>
          <Box
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="h4" fontWeight={800}>
              {data.collections}
            </Typography>
            <Typography variant="subtitle1">Collections</Typography>
          </Box>
          <Box
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="h4" fontWeight={800}>
              {data.followers}
            </Typography>
            <Typography variant="subtitle1">Followers</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
