import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import ModalPopover from "../Modal";
import Unlock from "../../assets/img/unlock.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import ModalAccess from "../Modal/access";

const useStyles = makeStyles((theme) => ({
  chipPosition: {
    position: "absolute",
    right: "20px",
    top: "20px",
    zIndex: "10",
  },
  media: {
    filter: "blur(12px)",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "rgba(18, 24, 38, 0.8)",
    opacity: 0.5,
  },
  overlayContent: {
    position: "absolute",
    top: 0,
    left: 0,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    flexFlow: "column",
    // [theme.breakpoints.up('sm')]: {
    //  border: '1px solid black'
    // },
  },
  overlayText: {
    fontSize: "19px",
    lineHeight: "28px",
  },
  overlayPinkText: {
    color: "#F9C7C4",
    fontWeight: "bold",
  },

  feedCard: {
    [theme.breakpoints.up("sm")]: {
      background: "rgba(255, 255, 255, 0.05)",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.12)",
    },
  },
  feedImage: {
    height: "350px",
    [theme.breakpoints.up("xl")]: {
      height: "420px",
    },
  },
}));

export default function Feed({ isFeed, post, user }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openSettingsPopover, setOpenSettingsPopover] = useState(false);
  const [openAccessPopover, setOpenAccessPopover] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNavigate = (e) => {
    e.stopPropagation();
    navigate(`/profile/${user.username}`);
  };

  const handleClick = () => {
    setOpenSettingsPopover(true);
  };

  const handleAccessClick = () => {
    setOpenAccessPopover(true);
  };

  const handleCloseConfirmPopOver = () => {
    setOpenSettingsPopover(false);
  };

  const handleCloseAccessPopOver = () => {
    setOpenAccessPopover(false);
  };
  return (
    <>
      <Card elevation={0} className={classes.feedCard}>
        {isFeed && (
          <CardHeader
            avatar={
              <Avatar
                aria-label="model"
                src={user.profile_image}
                onClick={(e) => {
                  handleNavigate(e);
                }}
              />
            }
            action={
              <IconButton aria-label="settings" onClick={handleClick}>
                <MoreHorizOutlinedIcon sx={{ color: "secondary.iconColor" }} />
              </IconButton>
            }
            title={
              <Link
                to={`/profile/${user.username}`}
                style={{
                  textDecoration: "none",
                  color:
                    localStorage.getItem("mode") === "dark"
                      ? "#F9C7C4"
                      : "#000000",
                }}
              >
                <Typography variant="h6" component="div">
                  {user.username}
                </Typography>
              </Link>
            }
          />
        )}
        <Swiper
          pagination={true}
          modules={[Pagination]}
          id={post.title}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          className={`${
            localStorage.getItem("mode") === "dark" ? "dark" : "light"
          } mySwiper`}
        >
          {post.images.length > 1 && (
            <Chip
              label={`${activeIndex + 1} / ${post.images.length}`}
              variant="outlined"
              className={classes.chipPosition}
              style={{
                background:
                  localStorage.getItem("mode") === "dark" ? "" : "#ffffffbf",
                border: localStorage.getItem("mode") === "dark" ? "" : "none",
              }}
            />
          )}
          {post.images.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <CardMedia
                  onClick={() => {
                    navigate(`/profile/${user.username}/${post.title}`);
                  }}
                  component="img"
                  alt="green iguana"
                  height="350"
                  image={image}
                  className={`${
                    post.preview_images === false && classes.media
                  }  ${classes.feedImage}`}
                />
                {post.preview_images === false && (
                  <Box onClick={handleAccessClick}>
                    <div className={classes.overlay}></div>
                    <Box className={classes.overlayContent}>
                      <Box className={classes.overlayImage}>
                        <img src={Unlock} alt="preview" />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          component="div"
                          className={classes.overlayText}
                        >
                          <span className={classes.overlayPinkText}>
                            {" "}
                            Purchase{" "}
                          </span>{" "}
                          or{" "}
                          <span className={classes.overlayPinkText}>
                            {" "}
                            Subscribe{" "}
                          </span>
                        </Typography>
                        <Typography
                          variant="body2"
                          component="div"
                          className={classes.overlayText}
                          style={{ textAlign: "center" }}
                        >
                          to View
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>

        <CardContent>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 5,
              marginTop: -18,
            }}
          >
            {/* two icons both end */}
            <IconButton
              size="large"
              aria-label="add"
              color="inherit"
              sx={{ marginLeft: -1.5 }}
            >
              <FavoriteBorderIcon sx={{ color: "primary.iconColor" }} />
            </IconButton>

            <IconButton
              size="large"
              aria-label="add"
              color="inherit"
              sx={{ marginRight: -1.5 }}
            >
              <BookmarkBorderOutlinedIcon sx={{ color: "primary.iconColor" }} />
            </IconButton>
          </Box>

          <Box>
            <Typography variant="h6" component="span">
              {post.purchases} Purchases
            </Typography>
            <Box component="span" paddingX={1}>
              |
            </Box>
            <Typography variant="h6" component="span">
              {post.views} Views
            </Typography>
          </Box>

          <Link
            to={`/profile/${user.username}`}
            style={{
              textDecoration: "none",
            }}
          >
            <Typography>
              <Typography
                gutterBottom
                variant="h6"
                component="span"
                style={{
                  color:
                    localStorage.getItem("mode") === "dark"
                      ? "#F9C7C4"
                      : "#000000",
                }}
              >
                {user.name || user?.username}
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                style={{
                  color:
                    localStorage.getItem("mode") === "dark"
                      ? "#FFFFFF"
                      : "#000000",
                }}
              >
                {post.caption.length > 89
                  ? " " + post.caption.slice(0, 89) + "... more"
                  : " " + post.caption}
              </Typography>
            </Typography>
          </Link>
        </CardContent>

        {!user.subscribed && (
          <CardActions>
            <Grid container spacing={2} style={{ marginBottom: "15px" }}>
              {!post.purchased && (
                <Grid item xs={7}>
                  <Button
                    variant="contained"
                    color="pink"
                    fullWidth
                    style={{ boxShadow: "none" }}
                    onClick={handleAccessClick}
                  >
                    <Typography
                      variant="p"
                      style={{
                        fontSize: "15px",
                        fontWeight: "700",
                        color: "#121826",
                      }}
                    >
                      ONLY {post.price}
                    </Typography>
                  </Button>
                </Grid>
              )}
              <Grid item xs={post.purchased ? 12 : 5}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={handleAccessClick}
                >
                  <Typography
                    variant="p"
                    style={{
                      fontSize: "15px",
                      fontWeight: "700",
                      color: "secondary",
                    }}
                  >
                    Subscribe
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        )}
      </Card>
      <ModalPopover
        open={openSettingsPopover}
        onClose={() => handleCloseConfirmPopOver()}
      />

      <ModalAccess
        open={openAccessPopover}
        onClose={() => handleCloseAccessPopOver()}
      />
    </>
  );
}
