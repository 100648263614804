import {
  Grid,
  Avatar,
  Typography,
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import sendIcon from "../../assets/img/sendIcon.svg";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  userMessageList: {
    borderRight: "1px solid #DBDBDB",
  },

  messageContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: `calc(100vh - ${225}px)`,
  },

  messageHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
  },

  messageList: {
    display: "flex",
    padding: "8px",
    justifyContent: "space-between",
  },

  accountName: {
    display: "flex",
    borderBottom: "1px solid #DBDBDB",
    padding: "14px",
  },

  arrowIcon: {
    padding: "0px !important",
    marginTop: "-2px !important",
  },

  sendText: {
    fontSize: "15px",
    color: "#121826",
  },

  sendBtn: {
    alignSelf: "center",
    boxShadow: "none",
    margin: "25px 0px",
  },

  message: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },

  messageListContainer: {
    overflowY: "scroll",
    height: `100%`,
  },
  searchInput: {
    padding: "8px 16px",
  },
}));

const testData = [
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "terrylucas",
    message: "Sent a photo",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "harryprescott",
    message: "Seen By all",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "ednamanz",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "christinasterling",
    message: "Sent a voice message",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "lauramatthews",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "harryprescott",
    message: "Seen By all",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "ednamanz",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "christinasterling",
    message: "Sent a voice message",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "lauramatthews",
    message: "Active 1h ago",
  },
];

const MessageMbView = ({ navType }) => {
  const classes = useStyles();

  const CustomOutlinedInput = styled(OutlinedInput)({
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid pink",
      },
      "& .MuiSvgIcon-root": {
        color: "pink",
      },
    },
  });

  useEffect(() => {
    navType("inbox");
  }, []);

  return (
    <Grid item container>
      <Grid item xs={12}>
        <Box className={classes.messageListContainer}>
          <Box className={classes.searchInput}>
            <FormControl variant="outlined" fullWidth>
              <CustomOutlinedInput
                id="search"
                type={"search"}
                size="small"
                placeholder="Search"
                sx={{ borderRadius: 3 }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "secondary.iconColor" }} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>

          <Box className={classes.messageHeader}>
            <Typography variant="p">Messages</Typography>
            <Typography variant="subtitle1">Requests</Typography>
          </Box>
          {testData.map((item) => {
            return (
              <Box className={classes.messageList}>
                <Box style={{ display: "flex" }}>
                  <Avatar
                    alt="Remy Sharp 3"
                    src={item.avatar}
                    sx={{ width: 45, height: 45 }}
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                  <Box
                    style={{
                      paddingLeft: "15px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="subtitle1">{item.username}</Typography>
                    <Typography variant="subtitle1" className={classes.message}>
                      {item.message}
                    </Typography>
                  </Box>
                </Box>
                <IconButton className={classes.cameraIcon}>
                  <CameraAltOutlinedIcon />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default MessageMbView;
