import {
  Grid,
  List,
  Avatar,
  Typography,
  Box
} from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";

const useStyles = makeStyles(() => ({

  userName: {
    fontWeight: '700'
  },

  content: {
    paddingLeft: "40px !important",
    paddingRight: "40px !important",
  },

  notification: {
    display: 'flex',
    marginBottom: '20px'
  },

  notificationText: {
    alignSelf: 'center',
    marginLeft: "10px",
    fontFamily: 'Avenir',
    fontSize: '18px',
    width: "80%",
    marginRight: "10px",
  },
  notificationTime: {
    color: "#8E8E8E"
  }


}));

const NotificationPage = ({ navType, header }) => {
  const classes = useStyles();

  useEffect(() => {
    navType("notification");
    header("Notifications")
  }, []);

  const testData = [
    {
      avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      message: "started following you.",
      userName: 'tanya',
      type: 'following',
      notificationTime: '3d'
    },

  ]

  const weekData = [
    {
      avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      message: "started following you.",
      userName: 'marta',
      type: 'follow',
      notificationTime: '3d'
    },
    {
      avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      message: "started following you.",
      userName: 'marta',
      type: 'follow',
      notificationTime: '3d'
    },
    {
      avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      message: "has tagged you in a post.",
      userName: 'annag',
      type: 'post',
      postImage: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      notificationTime: '5d'
    },
    {
      avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      message: "liked your post.",
      userName: 'terrylucas,marta,annag',
      userCount: '16',
      type: 'post',
      postImage: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
    },

    {
      avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      message: "started following you.",
      userName: 'marta',
      type: 'follow',
      notificationTime: '6d'
    },

    {
      avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      message: "liked your post.",
      userName: 'annag,annag',
      userCount: '32',
      type: 'post',
      postImage: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      notificationTime: '6d'
    },
    {
      avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      message: "liked your post.",
      userName: 'tanya',
      type: 'post',
      notificationTime: '6d',
      postImage: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
    },
    {
      avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      message: "mentioned you in the comment:",
      userName: 'terrylucas',
      type: 'mentioned',
      postImage: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      notificationTime: '6d',
      comment: 'love your picture.'
    },
    {
      avatar: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      message: "mentioned you in the comment:",
      userName: 'marta',
      type: 'mentioned',
      postImage: 'https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc',
      notificationTime: '7d',
      comment: 'happy birthday to you.'
    },
  ]


  return (
    <Grid container spacing={2} style = {{marginBottom: '58px'}}>
      <Grid item xs={12} className = {classes.content}>
        <Typography variant="h4" style = {{textAlign: "initial", marginBottom : '20px'}}>
          This Week
        </Typography>
          {testData.map(item => {
            return (
              <Box className = {classes.notification}>
                <Avatar
                  alt="Remy Sharp 3"
                  src={item.avatar}
                  sx={{ width: 50, height: 50 }}
                  style={{
                    borderRadius: '50%',
                  }}

                />
                <div className = {classes.notificationText}>
                  <Typography variant="span" className={classes.userName}>
                    {item.userName} &nbsp;                   
                  </Typography>

                  <Typography variant="span">
                  {item.message}
                  </Typography> 
                  <Typography variant="span" className={classes.notificationTime}>
                  {item.notificationTime}
                  </Typography> 
                </div>
                {item.type === "following" && 
                <Button variant="contained" color="pink" style = {{marginLeft: "auto", alignSelf: 'center'}}>
                  <Typography
                    variant="p"
                    style={{
                      color: "#121826",
                    }}
                  >
                    Following
                  </Typography>                
                  </Button>
                }

              {item.type === "follow" && 
                <Button variant="contained" color="pink" style = {{marginLeft: "auto", alignSelf: 'center'}}>
                  <Typography
                    variant="p"
                    style={{
                      color: "#121826",
                    }}
                  >
                    Follow
                  </Typography>                
                  </Button>
                }

                {(item.type === "post" || item.type === "mentioned" || item.type === "liked") && 
                  <Avatar
                  src={item.postImage}
                  sx={{ width: 50, height: 50 }}
                  style = {{marginLeft: "auto", alignSelf: 'center'}}
                  variant = "square"
                  />
                }
              </Box>
            )
          })
          }
      </Grid>
      <Grid item xs={12}> 
   <Divider/>
</Grid>
      <Grid item xs={12} className = {classes.content}>
        <Typography variant="h4" style = {{textAlign: "initial", marginBottom : '20px'}}>
          This Month
        </Typography>
          {weekData.map(item => {
            return (
              <Box className = {classes.notification}>
                <Avatar
                  alt="Remy Sharp 3"
                  src={item.avatar}
                  sx={{ width: 50, height: 50 }}
                  style={{
                    borderRadius: '50%',
                  }}

                />
                <div className = {classes.notificationText}>
                  <Typography variant="span" className={classes.userName}>
                    {item.userName} &nbsp;   
                  </Typography>
                  {item.userCount &&
                  (
                    <>
                    <Typography variant="span" >
                      and &nbsp;
                  </Typography>
                  <Typography variant="span" className={classes.userName}>
                    {item.userCount}    &nbsp;             
                  </Typography>
                  </>
                  )}

                  <Typography variant="span">
                  {item.message} {item.type === "mentioned" && item.comment}
                  </Typography>   


                  <Typography variant="span" className={classes.notificationTime}>
                  {item.notificationTime}
                  </Typography>   
                </div>
                
                {item.type === "follow" && 
                <Button variant="contained" color="pink" style = {{marginLeft: "auto", alignSelf: 'center'}}>
                    <Typography
                    variant="p"
                    style={{
                      color: "#121826",
                    }}
                  >
                    Follow
                  </Typography> 
                </Button>
                }
                 {item.type === "following" && 
                <Button variant="contained" color="pink" style = {{marginLeft: "auto", alignSelf: 'center'}}>
                  <Typography
                    variant="p"
                    style={{
                      color: "#121826",
                    }}
                  >
                    Following
                  </Typography>                
                  </Button>
                }

                {(item.type === "post" || item.type === "mentioned" || item.type === "liked") && 
                <Avatar
                src={item.postImage}
                sx={{ width: 50, height: 50 }}
                style = {{marginLeft: "auto", alignSelf: 'center'}}
                variant = "square"
              />
                }
              </Box>
            )
          })
          }
      </Grid>
    </Grid>
  );
};

export default NotificationPage;
