import React, { useState } from "react";
import { Navigate ,   Outlet,
} from "react-router-dom";

const ProtectedRoute = () => {
const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("login"));

if(isLoggedIn  === "true")
 return <Outlet/>; 
else{
return <Navigate to="/login" replace />;
}
};

export default ProtectedRoute;