import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { Link, useNavigate } from "react-router-dom";
import Unlock from "../../assets/img/unlock.svg";
import { makeStyles } from "@mui/styles";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { getAllTrendingCollectionsByUser } from "../../utils/posts";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import useMatchMedia from "../../utils/useMatchMedia";

// import required modules
import { Box } from "@mui/material";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

const useStyles = makeStyles((theme) => ({
  chipPosition: {
    position: "absolute",
    right: "20px",
    top: "20px",
    zIndex: "10",
  },
  media: {
    filter: "blur(2px)",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "rgba(18, 24, 38, 0.8)",
    opacity: 0.5,
  },
  overlayContent: {
    position: "absolute",
    top: 0,
    left: 0,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    flexFlow: "column",
  },
  overlayText: {
    fontSize: "19px",
    lineHeight: "28px",
  },
  overlayPinkText: {
    color: "#F9C7C4",
    fontWeight: "bold",
  },
  profileAvatar: {
    height: '23px',
    width: '23px',
    cursor: "pointer"
  },
  viewMore: {
    fontFamily: 'Avenir',
  },
  viewMoreUsername: {
    fontFamily: 'Avenir',
    fontWeight: 600,
  },
  feedCard: {
    [theme.breakpoints.up("sm")]: {
       background: 'rgba(255, 255, 255, 0.05)',
       boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.12)"
    },
  },
  actionButton: {
    [theme.breakpoints.up("sm")]: {
      margin: '30px',
      marginTop: '0px'

   },
  }
}));

const Post = ({ data, username, userDetail }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [trendingCollections, setTrendingCollections] = useState([]);
  const isWebResolution = useMatchMedia("(min-width:640px)", true);

  useEffect(() => {
    setTrendingCollections(getAllTrendingCollectionsByUser(username));
  }, []);

  const handleNavigate = (e) => {
    e.stopPropagation();
    navigate(`/profile/${username}`);
  };

  return (
    <>
      <Box
        style={{
          marginBottom: "60px",
        }}
      >
        {!!data.length &&
          data.map((post, index) => {
            return (
              <>
                <Box>
                  <Card elevation={0} 
                  className={classes.feedCard} 
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          aria-label="model"
                          src={userDetail?.profile_image}
                          onClick={(e) => {
                            handleNavigate(e);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      }
                      action={
                        <IconButton aria-label="settings">
                          <MoreHorizOutlinedIcon sx={{ color: "secondary.iconColor" }} />
                        </IconButton>
                      }
                      title={
                        <Link
                          to={`/profile/${username}`}
                          style={{
                            textDecoration: "none",
                            color:
                              localStorage.getItem("mode") === "dark"
                                ? "#F9C7C4"
                                : "#000000",
                          }}
                        >
                          <Typography variant="h6" component="div">
                            {username}
                          </Typography>
                        </Link>
                      }
                    />
                    <Swiper
                      pagination={true}
                      modules={[Pagination]}
                      id={post.title}
                      onSlideChange={(swiper) =>
                        setActiveIndex(swiper.activeIndex)
                      }
                      className={`${localStorage.getItem("mode") === "dark"
                          ? "dark"
                          : "light"
                        } mySwiper`}
                    >
                      {post.images.length > 1 &&
                      <Chip
                        label={`${activeIndex + 1} / ${post.images.length}`}
                        variant="outlined"
                        className={classes.chipPosition}
                        style={{
                          background:
                            localStorage.getItem("mode") === "dark"
                              ? ""
                              : "#ffffffbf",
                          border:
                            localStorage.getItem("mode") === "dark"
                              ? ""
                              : "none",
                        }}
                      />
                      }
                      {post.images.map((image, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <CardMedia
                              onClick={() => {
                                navigate(`/profile/${username}/${post.title}`);
                              }}
                              component="img"
                              alt="green iguana"
                              height="350"
                              image={image}
                              className={
                                post.preview_images === false && classes.media
                              }
                            />
                            {post.preview_images === false && (
                              <Box>
                                <div className={classes.overlay}></div>
                                <Box className={classes.overlayContent}>
                                  <Box className={classes.overlayImage}>
                                    <img src={Unlock} alt="preview" />
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      component="div"
                                      className={classes.overlayText}
                                    >
                                      <span className={classes.overlayPinkText}>
                                        {" "}
                                        Purchase{" "}
                                      </span>{" "}
                                      or{" "}
                                      <span className={classes.overlayPinkText}>
                                        {" "}
                                        Subscribe{" "}
                                      </span>
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="div"
                                      className={classes.overlayText}
                                      style={{ textAlign: "center" }}
                                    >
                                      to View
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>

                    <CardContent>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: -18,
                        }}
                      >
                        {/* two icons both end */}
                        <IconButton
                          size="large"
                          aria-label="add"
                          color="inherit"
                          sx={{ marginLeft: -1.5 }}
                        >
                          <FavoriteBorderIcon
                            sx={{ color: "primary.iconColor" }}
                          />
                        </IconButton>

                        <IconButton
                          size="large"
                          aria-label="add"
                          color="inherit"
                          sx={{ marginRight: -1.5 }}
                        >
                          <BookmarkBorderOutlinedIcon
                            sx={{ color: "primary.iconColor" }}
                          />
                        </IconButton>
                      </Box>

                      <Box>
                        <Typography variant="h6" component="span">
                          {post.purchases} Purchases
                        </Typography>
                        <Box component="span" paddingX={1}>
                          |
                        </Box>
                        <Typography variant="h6" component="span">
                          {post.views} Views
                        </Typography>
                      </Box>

                      <Link
                        to={`/profile/${username}`}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Typography>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="span"
                            style={{
                              color:
                                localStorage.getItem("mode") === "dark"
                                  ? "#F9C7C4"
                                  : "#000000",
                            }}
                          >
                            {username}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="caption"
                            style={{
                              color:
                                localStorage.getItem("mode") === "dark"
                                  ? "#FFFFFF"
                                  : "#000000",
                            }}
                          >
                            {post.caption.length > 89
                              ? " " + post.caption.slice(0, 89) + "... more"
                              : " " + post.caption}
                          </Typography>
                        </Typography>
                      </Link>
                    </CardContent>
                    {!userDetail.subscribed && (
                      <CardActions>
                        <Grid container spacing={2} className = {classes.actionButton}>
                          {!userDetail.purchased && (
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                color="pink"
                                fullWidth
                                style={{ boxShadow: "none" }}
                              >
                                <Typography
                                  variant="p"
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "700",
                                    color: "#121826",
                                  }}
                                >
                                  ONLY {post.price}
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                          <Grid item xs={userDetail.purchased ? 12 : 6}>
                            <Button variant="contained" color="pink" fullWidth
                              style={{ boxShadow: "none" }}
                            >
                              <Typography
                                variant="p"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "700",
                                  color: "#121826",
                                }}
                              >
                                Subscribe
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </CardActions>
                    )}
                  </Card>
                </Box>
                {isWebResolution && 
                <Box style={{ marginTop: "30px" }}>
                  <Card elevation={0} className={classes.feedCard} 
                  >
                    <CardHeader
                      avatar={<Avatar
                        src={userDetail?.profile_image}
                        fontSize="small"
                        className={classes.profileAvatar}
                        onClick={(e) => {
                          handleNavigate(e);
                        }} aria-label="model" />}
                      title={
                        <Link
                          to={`/profile/${username}`}
                          style={{
                            textDecoration: "none",
                            color:
                              localStorage.getItem("mode") === "dark"
                                ? "#F9C7C4"
                                : "#000000",
                          }}
                        >
                          <Typography variant="span" className={classes.viewMore}>
                            View more from &nbsp;
                          </Typography>
                          <Typography variant="span" className={classes.viewMoreUsername}>
                            {username}
                          </Typography>
                        </Link>
                      }
                    />
                    <Grid container spacing={2} style={{ padding: " 0px 16px" }}>
                      {!!trendingCollections &&
                        trendingCollections.map((data, index) => {
                          return (
                            <Grid
                              item
                              xs={3}
                              key={index}
                              sx={{ position: "relative" }}
                            >
                              <CardMedia
                                component="img"
                                className={classes.imageCard}
                                image={data.image}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Card>
                </Box>
                }
              </>
              // <Feed isFeed={false} key={index} post={post} user={{ username: username }} />
            );
          })}
      </Box>
    </>
  );
};

export default Post;
