import { Slide, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PostTabs from "../../Components/Profile/PostTabs";
import UserDescription from "../../Components/Profile/UserDescription";
import UserInfo from "../../Components/Profile/UserInfo";
import Story from "../../Components/Story";
import { getAllPostDataByUser, getAllCreators } from "../../utils/posts";
import { makeStyles } from "@mui/styles";
import useMatchMedia from "../../utils/useMatchMedia";
import profileBanner from "../../assets/img/profile_banner.png";

const ProfilePage = ({ navType, profileData }) => {
  const [data, setData] = useState({});
  const [creators, setCreators] = useState([]);
  const { username } = useParams();
  const isWebResolution = useMatchMedia("(min-width:640px)", true);

  useEffect(() => {
    setCreators(getAllCreators());
    setData(getAllPostDataByUser(username));
    profileData(username);
  }, [username]);

  useEffect(() => {
    navType("profile");
  }, []);

  const useStyles = makeStyles((theme) => ({
    profilePage: {
      position: "relative",
      [theme.breakpoints.up("xs")]: {
        marginBottom: "50px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "50%",
        margin: "auto",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.12)",
        borderRadius: "10px",
        marginTop: "40px",
      },
    },
    profileBanner: {
      backgroundImage: `url(${profileBanner})`,
      width: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      color: "#FFF",
      postion: "relative !important",
    },

    profileInfo: {
      display: "flex",
      [theme.breakpoints.up("xl")]: {
        padding: "26px 0px",
      },
    },
    profileContainer: {
      postion: "relative !important",
    },

    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      // backgroundColor: "rgba(0, 0, 0, 0.5)",
    },

    userData: {
      flexBasis: "40%",
      zIndex: "10",
    },

    storyList: {
      width: "42%",
      flexBasis: "60%",
      alignSelf: "end",
      paddingLeft: "18%",
    },
  }));
  const classes = useStyles();

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <div
        style={{
          marginBottom: "50px",
        }}
      >
        <div className={classes.profilePage}>
          {!isWebResolution && (
            <>
              <UserInfo
                data={{
                  profile_image: data.profile_image,
                  postCount: data?.postCount,
                  collections: data.collections,
                  followers: data.followers,
                }}
              />
              <UserDescription data={{ bio: data.bio, name: data.name }} />
              <Story list={creators} source="profile" />
            </>
          )}
          {isWebResolution && (
            <Box className={classes.profileBanner}>
              <Box className={classes.profileInfo}>
                <Box className={classes.userData}>
                  <UserInfo
                    data={{
                      profile_image: data.profile_image,
                      postCount: data?.postCount,
                      collections: data.collections,
                      followers: data.followers,
                    }}
                  />
                  <UserDescription data={{ bio: data.bio, name: data.name }} />
                </Box>
                <Box className={classes.storyList}>
                  <Story list={creators} source="profile" />
                </Box>
              </Box>
              <Box className={classes.overlay}></Box>
            </Box>
          )}
          <PostTabs data={data} />
        </div>
      </div>
    </Slide>
  );
};

export default ProfilePage;
