import React, { useEffect } from "react";
import { Grid, List, ListItem, ListItemText, Slide } from "@mui/material";
import Pages from "../../../Components/Pages";
import { makeStyles } from "@mui/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  listIcon: {
    color: "primary",
    height: "30px",
    width: "30px",
  },

  listItem: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    paddingTop: "15px",
    paddingBottom: "15px",
    display: "flex !important",
    justifyContent: "space-between !important",
  },

  text: {
    fontWeight: "500 !important",
    fontFamily: "Avenir !important",
  },
  headingText: {
    fontWeight: "800 !important",
    fontFamily: "Avenir !important",
    fontSize: '15px'
  },
  subText: {
    fontWeight: "300 !important",
    fontFamily: "Avenir !important",
    color: "gray",
  },
}));

const AccountLanguagePage = ({ navType, header }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    navType("accountPages");
    header("Account");
  }, []);

  return (
    <Pages>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Grid item xs={12}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.list}
          >
            <p className={classes.headingText}>Security</p>
            <ListItem button className={classes.listItem} onClick={() => navigate('/account/username/edit')}>
              <div>
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="Username"
                />
                <ListItemText
                  classes={{ primary: classes.subText }}
                  primary="@AnungJagatara"
                />
              </div>
              <ChevronRightIcon className={classes.listIcon} />
            </ListItem>
            <Divider />
            <ListItem button className={classes.listItem} onClick={() => navigate('/account/email/edit')}>
              <div>
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="Email"
                />
                <ListItemText
                  classes={{ primary: classes.subText }}
                  primary="anungjagatara@email.com"
                />
              </div>
              <ChevronRightIcon className={classes.listIcon} />
            </ListItem>
            <Divider />
            <ListItem button className={classes.listItem} onClick={() => navigate('/account/phone/edit')}>
              <div>
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="Phone"
                />
                <ListItemText
                  classes={{ primary: classes.subText }}
                  primary="+1 123 456 7890"
                />
              </div>
              <ChevronRightIcon className={classes.listIcon} />
            </ListItem>
            <Divider />
            <p className={classes.headingText}>Security</p>
            <ListItem button className={classes.listItem} onClick={() => navigate('/account/password/edit')}>
              <ListItemText
                classes={{ primary: classes.text }}
                primary="Password"
              />
              <ChevronRightIcon className={classes.listIcon} />
            </ListItem>
            <Divider />
            <ListItem button className={classes.listItem}>
              <ListItemText
                classes={{ primary: classes.text }}
                primary="Two Factor Authentication"
              />
              <ChevronRightIcon className={classes.listIcon} />
            </ListItem>
            <Divider />
          </List>
        </Grid>
      </Slide>
    </Pages>
  );
};

export default AccountLanguagePage;
