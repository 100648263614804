import { Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PostComponent from '../../Components/Post';
import { getUserPostByTitle,getUserDetailsByUsername } from '../../utils/posts';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme)=>({
  post: {
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      margin: 'auto',
      // boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.12)',
      borderRadius: '10px',
      marginTop: "40px"
    },
  }
  })
  )

const PostPage = ({ navType , profileData}) => {
  const { username, post } = useParams();

  const [data, setData] = useState({});
  const [userDetail, setUserDetail] = useState({});

  const classes = useStyles();


  useEffect(() => {
    let response = getUserPostByTitle(username, post)
    let userDetailResponse = getUserDetailsByUsername(username)
    setData(response)
    profileData(username)
    setUserDetail(userDetailResponse)
  }, [username])

  useEffect(() => {
    navType('profile');

  }, []);

  return (
    <Slide
      direction="left"
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <div style={{
        marginBottom: '50px',
      }}>
      <div className={classes.post}>
        <PostComponent data={data} username={username} userDetail ={ userDetail}/>
      </div>
      </div>
    </Slide>
  );
};

export default PostPage;
