import React, { useEffect } from "react";
import { Grid, Avatar, Typography, Slide } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Pages from "../../../../Components/Pages";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  listIcon: {
    color:
      localStorage.getItem("mode") == "dark" ? "rgba(18, 24, 38, 0.8)" : "",
    height: "35px",
    width: "35px",
  },

  listItem: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  text: {
    fontWeight: "300",
    marginBottom: "0px !important",
  },
}));

const AccountLanguagePage = ({ navType, header }) => {
  const classes = useStyles();

  useEffect(() => {
    navType("accountPages");
    header("Change Username");
  }, []);

  return (
    <Pages>
      <Slide
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
      >
        <Grid item xs={12}>
          <Box
            sx={{
              marginTop: "60px",
              marginBottom: "30px",
            }}
          >
            <p className={classes.text}>Email</p>
            <TextField
              fullWidth
              hiddenLabel
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: { fontWeight: 300, fontStyle: 'normal', fontSize: '16px' }
              }}
              defaultValue="anungjagatara@email.com"
              style={{ padding: "10px 10px !important" }}
            />
          </Box>
          <Button
            variant="contained"
            color="pink"
            fullWidth
            style={{
              boxShadow: "none",
              marginBottom: "20px",
              padding: "10px 0px",
            }}
          >
            <Typography
              variant="p"
              style={{
                fontSize: "15px",
                fontWeight: "700",
                color: "#121826",
              }}
            >
              Save
            </Typography>
          </Button>
        </Grid>
      </Slide>
    </Pages>
  );
};

export default AccountLanguagePage;
