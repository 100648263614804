import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@mui/styles";
import Pages from "../../../../Components/Pages";
import Button from "@mui/material/Button";
import { Grid, Slide, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  listIcon: {
    color:
      localStorage.getItem("mode") == "dark" ? "rgba(18, 24, 38, 0.8)" : "",
    height: "35px",
    width: "35px",
  },

  listItem: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  text: {
    fontWeight: "300",
    marginBottom: "0px !important",
  },
}));

const AccountPasswordPage = ({ navType, header }) => {
  const classes = useStyles();

  React.useEffect(() => {
    navType("accountPages");
    header("Change Password");
  }, []);

  const [values, setValues] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Pages>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Grid item xs={12}>
          <Box
            sx={{
              marginTop: "60px",
              marginBottom: "30px",
            }}
          >
            <div>
              <p className={classes.text}>Current Password</p>
              <FormControl sx={{ width: "100%" }} variant="filled">
                <FilledInput
                  id="filled-adornment-password"
                  fullWidth="true"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("currentPassword")}
                  style={{ padding: "10px 10px !important" }}
                  disableUnderline="true"
                  hiddenLabel="true"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div>
              <p className={classes.text}>New Password</p>
              <FormControl sx={{ width: "100%" }} variant="filled">
                <FilledInput
                  id="filled-adornment-password"
                  fullWidth="true"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("newPassword")}
                  style={{ padding: "10px 10px !important" }}
                  disableUnderline="true"
                  hiddenLabel="true"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div>
              <p className={classes.text}>Confirm Password</p>
              <FormControl sx={{ width: "100%" }} variant="filled">
                <FilledInput
                  id="filled-adornment-password"
                  fullWidth="true"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("confirmPassword")}
                  style={{ padding: "10px 10px !important" }}
                  disableUnderline="true"
                  hiddenLabel="true"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </Box>
          <Button
            variant="contained"
            color="pink"
            fullWidth
            style={{
              boxShadow: "none",
              marginBottom: "20px",
              padding: "10px 0px",
            }}
          >
            <Typography
              variant="p"
              style={{
                fontSize: "15px",
                fontWeight: "700",
                color: "#121826",
              }}
            >
              Save
            </Typography>
          </Button>
        </Grid>
      </Slide>
    </Pages>
  );
};

export default AccountPasswordPage;
