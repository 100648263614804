import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Logo from "../../assets/img/logo.svg";
import PinkLogo from "../../assets/img/pinklogo.svg";
import EventBus from "../../core/EventBus";
import { useNavigate } from "react-router-dom";

export default function NavBar(props) {
  const [darkMode, setDarkMode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    EventBus.on("setTheme", (theme) => {
      if (theme) {
        if (theme == "dark") {
          setDarkMode(true);
        } else {
          setDarkMode(false);
        }
      }
    });
    if (localStorage.getItem("mode") === "dark") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  const handleNotification = () => {
    navigate("/notification");
  };

  const handleInbox = () => {
    navigate("/inbox");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar color="light" elevation={0}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <IconButton sx={{ display: { md: "flex" } }}>
              {darkMode ? (
                <img src={PinkLogo} alt="logo" />
              ) : (
                <img src={Logo} alt="logo" />
              )}
            </IconButton>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { md: "flex" } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="add"
                color="inherit"
                sx={{ mr: 1 }}
                onClick={handleInbox}
              >
                <AddIcon
                  sx={{
                    borderRadius: "5px",
                    border: "2px solid",
                    color: "secondary.iconColor",
                  }}
                  fontSize="small"
                />
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="likes"
                // aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleNotification}
                color="inherit"
              >
                <FavoriteBorderIcon sx={{ color: "secondary.iconColor" }} />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  );
}
