import { data } from '../testdata'

const getAllPosts = () => {
    let posts = [];

    data.map((user) => {
        user.posts.map((post) => {
            posts.push(post)
        })
    })

    return posts;
}

const getAllUserAndPostData = () => {
    return data;
}

const getAllPostDataByUser = (username) => {
    return data.filter(datum => datum.username === username)[0];
}

const getAllCreators = () => {
    let creators = [];
    data.map((item) => {
        creators.push({
            username: item.username,
            profile_image: item.profile_image

        })
    })
    return creators;
}

const getAllTrendingCollections = () => {
    let trendingCollections = [];
    data.map((items) => {
        items?.posts.map(item => {
            trendingCollections.push({
                images: item.images,
                image: item.images[0],
                user: items.username,
                post: item.title,
                profile_image: items.profile_image
            })
        })
    })
    return trendingCollections;
}

const getAllTrendingCollectionsByUser = (username) => {
    let trendingCollections = [];
    let items = data.filter(item => item.username === username)

    items[0]?.posts.map(item => {
        trendingCollections.push({
            image: item.images[0],
            user: items[0].username,
            post: item.title
        })
    })

    return trendingCollections;
}

const getUserPostByTitle = (username, title) => {
    let postData = getAllPostDataByUser(username);
    return postData.posts.filter(post => post.title === title);
}

const getUserDetailsByUsername = (username) => {
    let userDetails = {};
    let user = data.filter(item => item.username === username)
       user.map((item) => {
        userDetails =  {
                username: item.username,
                profile_image: item.profile_image,
                subscribed: item.subscribed,
                purchased: item.purchased

            }
    })

    return userDetails;
}

export {
    getAllPosts,
    getAllUserAndPostData,
    getAllPostDataByUser,
    getAllCreators,
    getAllTrendingCollections,
    getUserPostByTitle,
    getAllTrendingCollectionsByUser,
    getUserDetailsByUsername
}
