import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import GridOnOutlinedIcon from "@mui/icons-material/GridOnOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import "swiper/css";
import "swiper/css/pagination";
import { withStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import LiveTvRoundedIcon from "@mui/icons-material/LiveTvRounded";
import useMatchMedia from "../../../utils/useMatchMedia";
import videoIcon from "../../../assets/img/videoIcon.svg";
import { makeStyles } from "@mui/styles";
import FilterNoneIcon from "@mui/icons-material/FilterNone";

// import required modules
import { Box, Card, CardMedia, Grid, IconButton } from "@mui/material";
import Feed from "../../Feed";

const useStyles = makeStyles((theme) => ({
  gridPostImage: {
    height: "130px",
    [theme.breakpoints.up("xl")]: {
      height: "269px",
      paddingTop: "10px",
    },
  },
  multiplePostIcon: {
    position: "absolute",
    top: "12px",
    right: 0,
    [theme.breakpoints.up("xl")]: {
      top: "26px",
      right: 0,
    },
  },
  multipleIcon: {
    color: "#F9C7C4",
    fontSize: "16px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px",
    },
  },
}));

const CustomTab = withStyles((theme) => ({
  root: {
    color: "primary",
  },
  selected: {
    color: "pink !important",
  },
}))(Tab);

const PostTabs = ({ data }) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const images = [];
  const isWebResolution = useMatchMedia("(min-width:640px)", true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //get all images for current user
  data.posts &&
    data.posts.map((post) => {
      images.push({ image: post.images, post: post.title });
    });

  console.log("images", images);
  const classes = useStyles();

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Post tabs"
        variant="fullWidth"
        TabIndicatorProps={{ style: { background: "pink", color: "pink" } }}
      >
        <CustomTab icon={<MenuIcon />} />
        <CustomTab icon={<GridOnOutlinedIcon />} />
        {isWebResolution && <CustomTab icon={<OndemandVideoRoundedIcon />} />}
        {isWebResolution && <CustomTab icon={<AssignmentIndOutlinedIcon />} />}
        {isWebResolution && <CustomTab icon={<LiveTvRoundedIcon />} />}
      </Tabs>
      <Box
        style={{
          marginBottom: "60px",
        }}
      >
        {value === 0 &&
          data.posts &&
          data.posts.map((post, index) => {
            return (
              <Box
                style={{
                  marginBottom: "20px",
                }}
              >
                <Feed
                  key={index}
                  post={post}
                  user={{
                    username: data.username,
                    name: data.name,
                    profile_image: data.profile_image,
                    bio: data.bio,
                  }}
                />
              </Box>
            );
          })}
        {value === 1 && (
          <Grid container spacing={2}>
            {images &&
              images.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={4}
                    key={index}
                    style={{ position: "relative" }}
                  >
                    {item?.image.length > 1 && (
                      <IconButton
                        aria-label="settings"
                        className={classes.multiplePostIcon}
                      >
                        <FilterNoneIcon className={classes.multipleIcon} />
                      </IconButton>
                    )}
                    <CardMedia
                      onClick={() => {
                        navigate(
                          `/discover/user/${data.username}/${item.post}`
                        );
                      }}
                      component="img"
                      alt="green iguana"
                      className={classes.gridPostImage}
                      image={item.image[0]}
                    />
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Box>
    </>
  );
};

export default PostTabs;
