import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';

export default function BottomBar() {
  const [value, setValue] = React.useState('recents');
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.primary.main,
      "&$selected": {
        color: "pink"
      }
    },
    selected: {}
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0 }} >
        <BottomNavigation value={value} 
        onChange={handleChange}            
         classes={classes}
        >
          <BottomNavigationAction
            value="home"
            icon={<HomeOutlinedIcon />}
            onClick={() => {
              navigate('/');
            }}
            classes={classes}
          />
          <BottomNavigationAction
            value="search"
            icon={<SearchOutlinedIcon />}
            onClick={() => {
              navigate('/discover');
            }}
            classes={classes}
          />
          <BottomNavigationAction
            value="nearby"
            classes={classes}
            icon={<GridOnOutlinedIcon />}
          />
          <BottomNavigationAction 
            value="folder" 
            classes={classes}
            onClick={() => {
              navigate('/account');
            }}
            icon={<AccountCircle />} />
        </BottomNavigation>
      </AppBar>
    </React.Fragment>
  );
}
