import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Typography,
  Slide,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import AvatarImage from "../../assets/img/avatar.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PersonIcon from "@mui/icons-material/Person";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SettingsIcon from "@mui/icons-material/Settings";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import Divider from "@mui/material/Divider";
import EventBus from "../../core/EventBus";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { ImportantDevices } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useMatchMedia from "../../utils/useMatchMedia";
import ProfileDesktopForm from "./ProfileDesktopForm";

const useStyles = makeStyles((theme) => ({

  root: {
    [theme.breakpoints.up('xs')]: {
      margin: "20px 20px 50px 30px",
    },

      [theme.breakpoints.up('sm')]: {
        width: '75%',
        margin: 'auto',
        marginTop: "20px"
    }
  },

  list: {
    padding: "0 !important",
  },

  listIcon: {
    color: "primary",
    marginRight: "10px",
    height: "24px",
    width: "24px",
  },

  listItem: {
    paddingLeft: "0 !important"
  },

  text: {
    fontWeight: "400 !important",
    fontSize: "15px !important",
    lineHeight: "20px !important",
    color: "primary !important",
    fontFamily: "Avenir !important",
    paddingLeft: "0 !important",
    marginLeft: "0 !important"
  },

  divider: {
    width: "100%",
  },

  accountContainer: {
    paddingTop: "30px"
  }

}));

const AccountPage = ({ navType , isLoggedIn}) => {
  const classes = useStyles();
  const [mode, setMode] = useState(localStorage.getItem("mode"));
  const navigate = useNavigate();
  const isWebResolution = useMatchMedia("(min-width:640px)", true);

  useEffect(() => {
    navType("account");
  }, []);

  const changeMode = () => {
    if (mode == "dark") {
      localStorage.setItem("mode", "light");
      setMode("light");
      EventBus.emit("setTheme", "light");
    } else {
      localStorage.setItem("mode", "dark");
      setMode("dark");
      EventBus.emit("setTheme", "dark");
    }
  };

  const handleLogout = () => {
    localStorage.setItem("login", "false");
    isLoggedIn("false")
    navigate('/login')
  }

  const navigateToRefer = () => {
    navigate('/referandearn')
  }

  return (
    <Slide
      direction="right"
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.root}>
        <Grid container spacing={2} className = {classes.accountContainer}>
        <Grid item xs={isWebResolution ? 4 : 12}>
          {!isWebResolution && 
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2} className="userInfo">
              <Grid item>
                <Avatar
                  alt="Remy Sharp"
                  src={AvatarImage}
                  sx={{ width: 76, height: 76 }}
                  style={{
                    border: localStorage.getItem("mode") === "dark" ? "" : "2px solid #DCDCDC",
                    borderRadius: "50%",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h6" component="div">
                  John Doe
                </Typography>
                <Typography variant="subtitle1" component="div" sx={{ color: "secondary.iconColor" }}>
                  @johnDoe
                </Typography>
              </Grid>
            </Grid>
          </Grid>
            }

          <Grid item xs={12} >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.list}
            >
              <ListItem button className={classes.listItem}>
                <PersonIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="My Profile"
                />
              </ListItem>

              <ListItem button className={classes.listItem}>
                <FavoriteBorderIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="My Likes"
                />
              </ListItem>

              <ListItem button className={classes.listItem}>
                <BookmarkBorderOutlinedIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="My Bookmarks"
                />
              </ListItem>

              <ListItem button className={classes.listItem}>
                <ShoppingBagOutlinedIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="My Purchases"
                />
              </ListItem>

              <ListItem button className={classes.listItem} onClick = {navigateToRefer}>
                <SpeakerNotesIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="My Referrals"
                />
              </ListItem>

              <ListItem button className={classes.listItem}>
                <SettingsIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="My Settings"
                  onClick={() => navigate('/account/setting')}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12} >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.list}
            >
              <ListItem button className={classes.listItem}>
                <CreditCardOutlinedIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="Add Credit (To Subscribe)"
                />
              </ListItem>

              <ListItem button className={classes.listItem}>
                <CreditCardOutlinedIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="Add Card (To Subscribe)"
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12} >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.list}
            >
              <ListItem button className={classes.listItem}>
                <HelpCenterIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="Help & Support"
                />
              </ListItem>

              <ListItem button onClick={changeMode} className={classes.listItem}>
                {mode === "dark" ? (
                  <Brightness4Icon className={classes.listIcon} />
                ) : (
                  <DarkModeIcon className={classes.listIcon} />
                )}
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary={mode !== "dark" ? "Dark Mode" : "Light Mode"}
                />
              </ListItem>

              <ListItem button className={classes.listItem}>
                <LanguageOutlinedIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="English"
                  onClick={() => navigate('/account/language')}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12} mb={4} >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.list}
            >
              <ListItem button className={classes.listItem} onClick = {handleLogout}>
                <LogoutOutlinedIcon className={classes.listIcon} />
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary="Logout"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
          {isWebResolution && 
            <Grid item xs = {8}>
              <ProfileDesktopForm/>
            </Grid>
          }
        </Grid>
      </div>
    </Slide>
  );
};

export default AccountPage;
