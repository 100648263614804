import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  
  root: {
    margin: "20px 20px 50px 30px",
  },

  list: {
    padding: "0 !important",
  },

  listIcon: {
    color: "primary",
    marginRight: "10px",
    height: "24px",
    width: "24px",
  },

  listItem : {
    paddingLeft: "0 !important"
  },

  text: {
    fontWeight: "400 !important",
    fontSize: "15px !important",
    lineHeight: "20px !important",
    color: "primary !important",
    fontFamily: "Avenir !important",
    paddingLeft: "0 !important",
    marginLeft: "0 !important"
  },

  divider: {
    width: "100%",
  },

}));
const Layouts = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default Layouts;
