import { Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Feed from '../../Components/Feed';
import { getAllUserAndPostData } from '../../utils/posts'
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme)=>({
homeFeed: {
  [theme.breakpoints.up('sm')]: {
    width: '50%',
    margin: 'auto',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    marginTop: "40px"
  },
}
})
)

const HomePage = ({ navType }) => {
  const [data, setData] = useState([]);
  const classes = useStyles();

 
  useEffect(() => {
    navType('navbar');
    setData(getAllUserAndPostData())
  }, []);

  return (
    <Slide
      direction="right"
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <div style={{
        marginBottom: '50px',
      }}>
        {data.map((datum, index) => {
          return(
            <React.Fragment key={index}>
             {datum.posts.map((post, index) => {
              return ( 
                <div className={classes.homeFeed}>
                 <Feed key={index} isFeed={true} post={post} user={{ username: datum.username, name: datum.name, profile_image: datum.profile_image, bio: datum.bio, subscribed: datum.subscribed  }} />
                </div>
              )
            })}
            </React.Fragment>
          )
        })
        }
      </div>
    </Slide>
  );
};

export default HomePage;
