import Popover from "@mui/material/Popover";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

  actions: {
    bottom: "0px !important",
    left: "0px !important",
    position: "fixed",
    maxHeight: "fit-content",
    maxWidth: "inherit",
    top: "initial !important",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    fontSize: "14px !important",
    backgroundColor: `${localStorage.getItem("mode") === "dark" ? "#1E2431" : "#FFF"}` ,
    backgroundImage: "none"
  },

  list: {
    padding: "0px !important"
  },
  itemText: {
    textAlign: "center",
    '& span, & svg': {
      fontSize: '14px',
      fontWeight: '700'
    }
  },
  listItem: {
    borderBottom: `${localStorage.getItem("mode") === "dark" ? "1px solid rgba(255, 255, 255, 0.1)" : "0.5px solid lightgray"}`,
  },
  actionButton: {
    flexDirection: "column",
    padding: "10px",
    height: "40%",
    width: "30%",
    borderRadius: "10px",
  },

  labelText: {
    marginRight: "120px",
    textAlign: "center",
    '& span, & svg': {
      fontSize: '14px',
      fontWeight: '700'
    }
  },

  accessBtn : {
    backgroundColor: theme.palette.pink.main,
    borderRadius: "10px",
    marginBottom: "10px",
    color: "rgba(18, 24, 38, 0.8)",
    fontWeight: "600",
    "&,&:focus": {
      backgroundColor: theme.palette.pink.main,
    },
    "&,&:hover": {
      backgroundColor: `${theme.palette.pink.main} !important`,
    },
  },
  subscribeBtn : {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "10px",
    color: theme.palette.secondary.main
  },
  header: {
    margin: "30px 20px 15px 20px", 
    textAlign: 'center',
    color: theme.palette.primary.main,
  },

  actionsBtn: {
    background: `${localStorage.getItem("mode") === "dark" ? "rgba(255, 255, 255, 0.05)" : "#e3e3e3"}`,
    margin: "15px 20px 25px 20px",
    borderRadius: "10px",
    border:  `${localStorage.getItem("mode") === "dark" ? "1px solid rgba(255, 255, 255, 0.1)" : "0.5px solid lightgray"}`,
  }

}))

export default function ModalAccess(open, onClose) {
  const classes = useStyles();
  return (
    <Popover
      open={open.open}
      onClose={open.onClose}
      anchorReference="anchorPosition"
      anchorPosition={{ bottom: 0 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      PaperProps={{
        style: { width: "100%"},
        classes: { root: classes.actions },
      }}
    >
      <Box className = {classes.header}>
        <Typography variant="h6" component="div">
          Access for 2,500 ONLY
        </Typography>
        <Typography variant="h6" component="div">
          or Subscribe to this users for 15,250 ONLY
        </Typography>
      </Box>
    
      <div
        style={{
          margin: "15px 20px",
        }}
      >
        <List aria-label="actions" className={classes.list}>

          <ListItem button className={classes.accessBtn}>
            <ListItemText
              className={classes.labelText}
              primary="Access"
            />
            <ListItemText
              className={classes.itemText}
              primary="for 2,500 ONLY"
            />
          </ListItem>

          <ListItem button className = {classes.subscribeBtn}>
            <ListItemText
              className={classes.labelText}
              primary="Subscribe"
            />
            <ListItemText
              className={classes.itemText}
              primary="for 25,000 ONLY"
            />
          </ListItem>

        </List>
        </div>
      <div
        className = {classes.actionsBtn}
      >
        <List aria-label="actions" className={classes.list}>
          <ListItem className={classes.listItem} button>
            <ListItemText
              className={classes.itemText}
              primary="Add to Favourites"
            />
          </ListItem>
          <ListItem className={classes.listItem} button>
            <ListItemText
              className={classes.itemText}
              primary="Share"
            />
          </ListItem>
          <ListItem button>
            <ListItemText className={classes.itemText} primary="Unfollow" />
          </ListItem>
        </List>
      </div>
    </Popover>
  );
}
