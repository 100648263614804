import { Grid, Avatar, Typography, Box } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import sendIcon from "../../assets/img/sendIcon.svg";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import useMatchMedia from "../../utils/useMatchMedia";
import MessageMbView from "./MessageMbView";

const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #DBDBDB",
    width: "80%",
    margin: "auto",
    marginTop: "50px",
    height: `calc(100vh - ${172}px)`,
  },

  userMessageList: {
    borderRight: "1px solid #DBDBDB",
  },

  messageContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: `calc(100vh - ${225}px)`,
  },

  messageList: {
    display: "flex",
    padding: "8px",
  },

  accountName: {
    display: "flex",
    borderBottom: "1px solid #DBDBDB",
    padding: "14px",
  },

  arrowIcon: {
    padding: "0px !important",
    marginTop: "-2px !important",
  },

  sendText: {
    fontSize: "15px",
    color: "#121826",
  },

  sendBtn: {
    alignSelf: "center",
    boxShadow: "none",
    margin: "25px 0px",
  },

  message: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },

  messageListContainer: {
    overflowY: "scroll",
    height: `calc(100vh - ${225}px)`,
  },
}));

const testData = [
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "terrylucas",
    message: "Imperdiet in sit rhoncus, eleifend tellu1234",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "lauramatthews",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "harryprescott",
    message: "Augue lectus potenti pellentesque.",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "ednamanz",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "christinasterling",
    message: "Imperdiet in sit rhoncus, eleifend tellu12",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "lauramatthews",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "harryprescott",
    message: "Augue lectus potenti pellentesque.",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "ednamanz",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "christinasterling",
    message: "Imperdiet in sit rhoncus, eleifend tellu12",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "lauramatthews",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "lauramatthews",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "harryprescott",
    message: "Augue lectus potenti pellentesque.",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "ednamanz",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "christinasterling",
    message: "Imperdiet in sit rhoncus, eleifend tellu12",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "lauramatthews",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "lauramatthews",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "harryprescott",
    message: "Augue lectus potenti pellentesque.",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "ednamanz",
    message: "Active 1h ago",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "christinasterling",
    message: "Imperdiet in sit rhoncus, eleifend tellu12",
  },
  {
    avatar:
      "https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc",
    username: "lauramatthews",
    message: "Active 1h ago",
  },
];

const MessagePage = ({ navType, profileData }) => {
  const classes = useStyles();
  const isWebResolution = useMatchMedia("(min-width:640px)", true);

  useEffect(() => {
    navType("inbox");
    profileData("tanya");
  }, []);

  return (
    <>
      {!isWebResolution ? (
        <MessageMbView navType={navType} />
      ) : (
        <Grid container className={classes.root}>
          <Grid item xs={4} className={classes.userMessageList}>
            <Box className={classes.accountName}>
              <Typography variant="h5" sx={{ fontWeight: "400" }}>
                shirleyromero
              </Typography>
              <KeyboardArrowDownOutlinedIcon
                sx={{ color: "primary.iconColor" }}
                className={classes.arrowIcon}
              />
            </Box>
            <Box className={classes.messageListContainer}>
              {testData.map((item) => {
                return (
                  <Box className={classes.messageList}>
                    <Avatar
                      alt="Remy Sharp 3"
                      src={item.avatar}
                      sx={{ width: 45, height: 45 }}
                      style={{
                        borderRadius: "50%",
                      }}
                    />
                    <Box
                      style={{
                        paddingLeft: "15px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="subtitle1">
                        {item.username}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.message}
                      >
                        {item.message}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box className={classes.messageContainer}>
              <Box>
                <img
                  src={sendIcon}
                  height="76"
                  weight="76"
                  style={{ marginBottom: "10px" }}
                />
              </Box>
              <Typography
                variant="p"
                style={{ marginBottom: "10px", fontSize: "22px" }}
              >
                Your Messages
              </Typography>
              <Typography variant="subtitle" style={{ fontSize: "14px" }}>
                {" "}
                Send private photos and messages to a friend or group.
              </Typography>
              <Button
                variant="contained"
                color="pink"
                className={classes.sendBtn}
              >
                <Typography variant="p" className={classes.sendText}>
                  Send Message
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MessagePage;
