import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
  Grid, Box, Typography, Link, Button
} from "@mui/material";
import referMobBanner from '../../assets/img/refermobBanner.png'
import twitterIcon from '../../assets/img/twitter.png'
import facebookIcon from '../../assets/img/facebook.png'
import messengerIcon from '../../assets/img/messenger.png'
import shareIcon from '../../assets/img/shareLink.png'
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import useMatchMedia from "../../utils/useMatchMedia";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import twitterLightIcon from '../../assets/img/twitter_light.png'
import facebookLightIcon from '../../assets/img/facebook_light.png'
import messengerLightIcon from '../../assets/img/messenger_light.png'
import shareLightIcon from '../../assets/img/share_light.png'

const useStyles = makeStyles((theme) => ({
 
  root: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    backgroundImage: `url(${referMobBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: 'rgba(0, 0, 0, 0.7)',
  },
  content: {
    textAlign: 'center',
    zIndex: '10',
    margin: '35px',
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
  },
 
  headerSection: {
    display: 'flex'
  },
  backBtn: {
    flexBasis: '10%'
  },

  headerText: {
    textAlign: 'center',
    fontSize: '32px',
    lineHeight: '48px',
    fontWeight: '700',
    flexBasis: '90%'
  },
  infoText: {
    fontSize: '18px',
    fontFamily: 'Inter !important',
    textAlign: 'center',
    opacity:'0.8',
    padding: '0px 30px',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor:  theme.palette.referPage.overlay
  },

  socialLightIcons: {
    display: 'flex',
    gap: '45px',
    justifyContent: 'center',
    visibility: theme.palette.referPage.displayLightIcons
  },

  socialDarkIcons: {
    display: 'flex',
    gap: '45px',
    justifyContent: 'center',
    visibility: theme.palette.referPage.displayDarkIcons
  },
  codeSection: {
    background: theme.palette.referPage.referalCode,
    borderRadius: '5px',
    padding: '20px',
    display: 'flex',
  },
  referralCode: {
    flexBasis: '75%',
    borderRight: '1px solid rgba(255, 255, 255, 0.15)',
  },
  code: {
    fontWeight: '700',
    fontSize: '37px',
    lineHeight: '43px'
  },
  copyCode: {
    flexBasis: '25%'
  },
  viewBtn: {
    alignSelf: 'auto',
    margin: '70px 30px',
    padding: '18px',
  },
  copyIcon: {
    fontSize: '32px',
    color: theme.palette.secondary.main
  }

}))

const ReferPage = ({ navType, header }) => {
  const classes = useStyles();
  const isWebResolution = useMatchMedia("(min-width:640px)", true);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
}

  useEffect(() => {
    navType('refer');
    header('Refer')

  }, []);

  return (
    <Grid container className={classes.root}>
      <Box className={classes.overlay}>
      </Box>
      <Grid item xs={12} className={classes.content}>
        <Box className={classes.headerSection}>
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                className = {classes.backBtn}
                onClick={() => handleGoBack()}
              >
              <ArrowBackIosIcon style = {{fontSize:'32px'}}/>
            </IconButton>
          <Typography className={classes.headerText}>Refer & Earn! </Typography>
        </Box>
        <Box>
        <Typography className={classes.infoText}>Earn credits by reffering to use on purchases or subscriptions or swap for other tokens.</Typography>
        </Box>

        <Box className={classes.codeSection}>
          <Box className = {classes.referralCode}>
            <Typography variant="p" component="div" style = {{marginBottom: '10px'}}>
              Your referral code
            </Typography>
            <Typography variant="p" component="div" className = {classes.code}>
              A0346G
            </Typography>
          </Box>
          <Box className = {classes.copyCode}>
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
            >
              <FilterNoneIcon className = {classes.copyIcon}/>
            </IconButton>
            <Typography variant="p" component="div" style = {{marginTop: '6px'}}>
              copy
            </Typography>
          </Box>

        </Box>
        <Box>
        <Typography variant="p" component = "div" style ={{marginBottom: '20px'}}>
          Share your referral code via:
        </Typography>
        <Box className={classes.socialDarkIcons}>
          <img src={twitterIcon} />
          <img src={facebookIcon} />
          <img src={messengerIcon} />
          <img src={shareIcon} />
        </Box>

        <Box className={classes.socialLightIcons}>
          <img src={twitterLightIcon} />
          <img src={facebookLightIcon} />
          <img src={messengerLightIcon} />
          <img src={shareLightIcon} />
        </Box>

        </Box>
        <Button
          variant="contained"
          color="pink"
          style={{ boxShadow: "none" }}
          className={classes.viewBtn}
        >
          <Typography
            variant="p"
            style={{
              fontSize: "15px",
              fontWeight: "700",
              color: "#121826",
            }}
          >
            View Your Stats
          </Typography>
        </Button>


      </Grid>






      {/* <Grid item xs = {12} className = {classes.upperSection}>
       <Box className={classes.contentText}>
          <Typography className={classes.headerText}>Refer & Earn! </Typography>
          <Typography className={classes.infoText}>Get ONLY rewards for every referral you make!</Typography>
       </Box>
        <Box className={classes.overlay}></Box>
       </Grid>
       <Grid item xs = {12} className = {classes.secondSection}>
         <Box className = {classes.linkSection}>
         <Typography variant = "p">
            Get your referral link to give your friends a $20 discount when they shop at Hot Beans. If they use it, we’ll reward you with $20.
         </Typography>
          <Box className = {classes.termsAndCondition}>
          <Link>
            <Typography variant="h6" component="div" style ={{color: "#F9C7C4"}}>
              Terms & Conditions
            </Typography>
          </Link>
          </Box>
          <Typography variant="p" >
            Invite now using:
          </Typography>
          <Box className = {classes.socialIcons}>
          <img src = {twitterIcon}/>
          <img src = {facebookIcon}/>

          <img src = {messengerIcon}/>
          <img src = {shareIcon}/>

          </Box>

          <Typography variant = "p">Or copy your personal link</Typography>
          <Box>
          <TextField
            id="filled-adornment-password"
            placeholder = "voucherify.io"
            fullWidth
            variant = "filled"
            className = {classes.personalLinkInput}
            
            InputProps={{ 
              disableUnderline: true,
              endAdornment:(
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center', backgroundColor:'#F9C7C4',height:55,width:50,marginRight: '-13px'}}>
                   <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <FilterNoneIcon style={{color:"#000"}}/>
                  </IconButton>
                </InputAdornment>
                </div>
              )

             }}
          />
          </Box>  
         </Box>
         
       </Grid> */}
    </Grid>
  )
};

export default ReferPage;
