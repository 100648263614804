import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

  profileForm: {
    // padding: "40px", 
    // paddingTop: "0px", 
  },

  listIcon: {
    color:
      localStorage.getItem("mode") == "dark" ? "rgba(18, 24, 38, 0.8)" : "",
    height: "35px",
    width: "35px",
  },

  listItem: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    paddingTop: "15px",
    paddingBottom: "15px",
  },

  text: {
    fontWeight: "300",
    marginBottom: "0px !important",
    fontFamily: "Karla !important",
    color: "rgba(18, 24, 38, 0.8)"
  },

  cameraIcon: {
    position: "absolute",
    top: '30%',
    left: "30%",
    zIndex: "1000"
  },

  profileImage: {
    position: 'block'
  },

  overlay: {
    background : 'rgba(0, 0, 0, 0.5)',
    borderRadius:"50%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    position: "absolute"
  }
}));

const ProfileDesktopForm = ({ }) => {
  const classes = useStyles();

  useEffect(() => {
  }, []);

  return (
    <Grid container spacing={4} className = {classes.profileForm}>

      <Grid item xs={12}>
        <div style={{ width: 118, height: 118, position: 'relative' }}>
        <img
          alt="Remy Sharp"
          src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/280833130278439.617c48fbf06cc.jpg"
          style={{
            borderRadius: "50%",
            width: "100%", 
            height: "100%",
            background: 'rgba(0, 0, 0, 0.5)'
          }}
          className = {classes.profileImage}
        />
      <IconButton
          size="large"
          edge="end"
          aria-label="likes"
          aria-haspopup="true"
          className = {classes.cameraIcon}
        >
          <PhotoCameraOutlinedIcon sx={{ color: "#FFFFFF" }} />
        </IconButton>
        <div className = {classes.overlay}>
</div>
        </div>

       
      </Grid>

      

      <Grid item xs={6}>
        <Typography className={classes.text}>Display Name</Typography>
        <TextField
          fullWidth
          hiddenLabel
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: { fontWeight: 300, fontStyle: 'normal', fontSize: '16px', fontFamily: 'Karla', 
          }
          }}
          defaultValue="Anung Jagatara"
          style={{ padding: "10px 10px !important" }}
        />
      </Grid>


      <Grid item xs={6}>
        <Typography className={classes.text}>UserName</Typography>
        <TextField
          fullWidth
          hiddenLabel
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: { fontWeight: 300, fontStyle: 'normal', fontSize: '16px',fontFamily: 'Karla', 
          }
          }}
          defaultValue="@AnungJanatara"
          style={{ padding: "10px 10px !important" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.text}>Bio</Typography>
        <TextField
          fullWidth
          hiddenLabel
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: { fontWeight: 300, fontStyle: 'normal', fontSize: '16px',fontFamily: 'Karla',
          }
          }}
          defaultValue="Just a girl who likes girls"
          style={{ padding: "10px 10px !important" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.text}>Location</Typography>
        <TextField
          fullWidth
          hiddenLabel
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: { fontWeight: 300, fontStyle: 'normal', fontSize: '16px',fontFamily: 'Karla'
          }
          }}
          defaultValue="New York, USA"
          style={{ padding: "10px 10px !important" }}
        />
      </Grid>
      <Grid item xs={12} style={{ display: 'grid', justifyContent: "end" }}>
        <Button
          variant="contained"
          color="pink"

          style={{
            boxShadow: "none",
            padding: "10px 30px",
          }}
        >
          <Typography
            variant="p"
            style={{
              fontSize: "15px",
              fontWeight: "700",
              color: "#121826",
              fontFamily: 'Karla',
            }}
          >
            Update
          </Typography>
        </Button>
      </Grid>

    </Grid>

  );
};

export default ProfileDesktopForm;
