import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Logo from "../../assets/img/logo.svg";
import WhiteLogo from "../../assets/img/whitelogo.svg";
import EventBus from "../../core/EventBus";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import NotificationMenu from "../../Components/Menu/NotificationMenu"
import SettingsMenu from "../../Components/Menu/SettingsMenu"
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

export default function WebNav(props) {
    const [darkMode, setDarkMode] = useState(false);
    const [openSettingsMenu, setOpenSettingsMenu] = useState(null);
    const [openNotificationMenu, setOpenNotificationMenu] = useState(null);
    const navigate = useNavigate();

    const CustomOutlinedInput = styled(OutlinedInput)({
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #E8E8E8",
            },
            "& .MuiSvgIcon-root": {
                color: "#E8E8E8",
            },
        },
    });
    const handleProfileClick = (event) => {
        setOpenSettingsMenu(event.currentTarget);
    };

    const  handleNotificationClick = (event) => {
        setOpenNotificationMenu(event.currentTarget)
    };



    useEffect(() => {
        EventBus.on("setTheme", (theme) => {
            if (theme) {
                if (theme == "dark") {
                    setDarkMode(true);
                } else {
                    setDarkMode(false);
                }
            }
        });
        if (localStorage.getItem("mode") === "dark") {
            setDarkMode(true);
        } else {
            setDarkMode(false);
        }
    }, []);

    const navigateToFeed = () => {
        navigate('/')
    }

    const navigateToExplore = () => {
        navigate('/discover')
    }

    const handleLogout = () => {
        localStorage.setItem("login", "false");
        navigate('/login')
    }

    const navigateToAccount = () => {
        navigate('/account')
    }

   const handleNotificationClose = () => {
    setOpenNotificationMenu(null)
   }

   const handleClose = () => {
    setOpenSettingsMenu(null)
   }

   const handleMessageClick = () => {
        navigate('/inbox')
   }

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar
                color="light"
                elevation={0}
                style={{
                    boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.05)",
                    display: "flex",
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <IconButton sx={{ display: { md: "flex" } }} onClick = {navigateToFeed}>
                            {darkMode ? (
                                <img src={WhiteLogo} alt="logo" />
                            ) : (
                                <img src={Logo} alt="logo" />
                            )}
                        </IconButton>

                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ display: { md: "flex",}, flex:"15%" }}>
                            <FormControl variant="outlined" fullWidth>
                                <CustomOutlinedInput
                                    id="search"
                                    type={"search"}
                                    size="small"
                                    placeholder="Search"
                                    sx={{ borderRadius: 15, 
                                        background:  localStorage.getItem("mode") == "dark" ? "rgba(249, 250, 249, 0.05)" : "#F9FAF9",

                                     }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ color: "#9D9C9D" }} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ display: { md: "flex" } }}>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="add"
                                color="inherit"
                                sx={{ mr: 1 }}
                                onClick = {navigateToFeed}
                            >
                                <HomeOutlinedIcon
                                    sx={{ color: "primary" }}
                                    fontSize="medium"
                                />
                            </IconButton>
                            
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="likes"
                                aria-haspopup="true"
                                color="inherit"
                                sx={{ mr: 1 }}
                                onClick={handleMessageClick}
                            >
                                <MessageOutlinedIcon sx={{ color: "primary" }} />
                            </IconButton>

                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="add"
                                color="inherit"
                                sx={{ mr: 1 }}
                                onClick = {navigateToExplore}
                            >
                                <ExploreOutlinedIcon
                                    fontSize="medium"
                                    sx={{ color: "primary" }}
                                />
                            </IconButton>
                            
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="likes"
                                aria-haspopup="true"
                                color="inherit"
                                sx={{ mr: 1 }}
                                onClick={handleNotificationClick}
                            >
                                <FavoriteBorderIcon sx={{ color: "primary" }} />
                            </IconButton>

                            <Box
                                style={{ margin: "auto", cursor: "pointer" }}
                                onClick={handleProfileClick}
                            >
                                <Avatar
                                    sx={{ width: 24, height: 24 }}
                                    aria-label="model"
                                    src="https://img-10.wfolio.com/QQnOXv7v2kVZIacE1VzrOiT2xYPlDRaVPLA4NxGOhAw/rs:fit:1280:0:0/aHR0cDovL3N0b3Jh/Z2Uud2ZvbGlvLnJ1/L3NpdGVzLzIwMTc4/L2Fzc2V0cy8xNjQ4/MTk3NTkwXzA2MDIx/NS5qcGc"
                                />
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
                
                <SettingsMenu openSettingsMenu = {openSettingsMenu} onSettingsClose = {handleClose} />             
                <NotificationMenu openNotificationMenu = {openNotificationMenu} onNotificationClose = {handleNotificationClose}/>

             
            </AppBar>
            <Toolbar />
        </React.Fragment>
    );
}
