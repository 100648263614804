import React from "react";
import { Box, Grid, TextField, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import PinkLogo from "../../../assets/img/pinkOnlyLogo.png";
import DesktopBg from "../../../assets/img/DesktopBg.png";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  bgContainer: {
    backgroundImage: `url(${DesktopBg})`,
    width: "100%",
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "50% 50%",
    fontFamily: "Karla",
  },

  loginContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100vh",
  },

  loginFormContainer: {
    width: "320px",
    background: "rgba(18, 24, 38, 0.6)",
    padding: "0px 2.5rem",
    marginRight: "3rem",
    [theme.breakpoints.up("xxl")]: {
      width: "450px",
      padding: "0px 2.5rem",
      marginRight: "3rem",
    },
  },

  label: {
    marginBottom: "0px !important",
    color: "#FFF",
    opacity: "0.8",
    fontSize: "0.75rem",
    letterSpacing: "0.05em",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1rem",
    },
  },

  actionHeader: {
    fontSize: "1.25rem",
    color: "#F9C7C4",
    display: "flex",
    justifyContent: "center",
    marginTop: "1.8rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.8rem",
      marginTop: "2.2rem",
      marginBottom: "2.2rem",
    },
  },
  signIn: {
    padding: "0 1rem",
    margin: "1rem 0",
    cursor: "pointer",
    opacity: "0.5",
    [theme.breakpoints.up("xxl")]: {
      padding: "0 1.5rem",
      margin: "1.5rem 0",
    },
  },
  signUp: {
    borderRight: "1px solid #F9C7C4",
    padding: "0 1rem",
    margin: "1rem 0",
    cursor: "pointer",
    [theme.breakpoints.up("xxl")]: {
      padding: "0 1.5rem",
      margin: "1.5rem 0",
    },
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    padding: "3.2rem",
    paddingTop: "0px",
    [theme.breakpoints.up("xxl")]: {
      paddingBottom: "9rem",
    },
  },
  bottomText: {
    alignSelf: "end",
    color: "#FFF",
  },
  welcomeText: {
    fontFamily: "Nunito !important",
    fontSize: "2rem !important",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "2.4rem !important",
    },
  },
  infoText: {
    fontFamily: "Nunito !important",
    fontSize: "1.25rem",
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem !important",
    },
  },
  submitBtn: {
    height: "3.125rem",
    marginBottom: "5rem !important",
    marginTop: "0.5rem !important",
    [theme.breakpoints.up("xxl")]: {
      marginBottom: "7rem !important",
      height: "3.5rem",
      fontSize: "1rem !important",
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: "12px",
    marginBottom: "1.75rem",
    border: "1px solid #FFF",
    height: "40px",
    color: "#FFF",
    [theme.breakpoints.up("xxl")]: {
      height: "55px",
      marginBottom: "2.25rem",
      fontSize: "1rem",
    },
  },
}));

const DesktopRegisterPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <div className={classes.bgContainer}>
      <Box className={classes.loginContainer}>
        <Grid item xs={12}>
          <Box
            style={{
              padding: "1.875rem",
              paddingTop: "0.75rem",
              paddingBottom: "0px",
            }}
          >
            <img src={PinkLogo} alt="logo" />
          </Box>
        </Grid>
        <Box className={classes.content}>
          <Box className={classes.bottomText}>
            <Typography className={classes.welcomeText}>Welcome !</Typography>
            <Typography className={classes.infoText}>
              First time, you should login or sign up
            </Typography>
          </Box>

          <Box className={classes.loginFormContainer}>
            <Grid item xs={12}>
              <Box className={classes.actionHeader}>
                <Box className={classes.signUp}>
                  <Typography variant="p">Sign up </Typography>
                </Box>
                <Box className={classes.signIn} onClick={navigateToLogin}>
                  <Typography variant="p">Sign in </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="p" className={classes.label}>
                  First Name
                </Typography>
                <StyledTextField fullWidth variant="outlined" />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="p" className={classes.label}>
                  Last Name
                </Typography>
                <StyledTextField fullWidth variant="outlined" />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="p" className={classes.label}>
                Email
              </Typography>

              <StyledTextField fullWidth variant="outlined" />

              <Typography variant="p" className={classes.label}>
                Password
              </Typography>

              <StyledTextField fullWidth variant="outlined" />

              <Typography variant="p" className={classes.label}>
                Confirm Password
              </Typography>

              <StyledTextField fullWidth variant="outlined" />

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="pink"
                  fullWidth
                  className={classes.submitBtn}
                >
                  Create New Accout
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default DesktopRegisterPage;
