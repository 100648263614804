import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function MessageNav(props) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar color="light" elevation={0}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <IconButton
              sx={{ display: { md: "flex" } }}
              style={{ mr: 2 }}
              onClick={() => handleGoBack()}
            >
              <ArrowBackIosIcon color="primary" />
            </IconButton>

            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="h3"
                component="span"
                style={{
                  display: "flex",
                }}
              >
                {props?.profileData}
              </Typography>
            </Box>

            <Box sx={{ display: { md: "flex" } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="video"
                color="inherit"
                sx={{ mr: 1 }}
              >
                <VideoCallOutlinedIcon fontSize="medium" color="primary" />
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="likes"
                // aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
              >
                <BorderColorOutlinedIcon
                  sx={{ color: "secondary.iconColor" }}
                />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  );
}
